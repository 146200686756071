//
// Platform - https://github.com/bestiejs/platform.js/
//
var platform = require('platform');
var Pikaday = require('pikaday');
var request = require('sync-request');
var moment = require('moment');
var Promise = require('bluebird');
const throttle = require('throttle-debounce/throttle');

// CHECKBOXES

$('.js-checkbox').on('click', function () {
	let $this = $(this),
		$label = $this.parent('.checkbox__label'),
		$labelAllArray = $label.closest('.checkbox').find('.checkbox__label');

	if ($this.hasClass('js-checkbox--all')) {
		if ($label.hasClass('checkbox__label--checked')) {
			$labelAllArray.each(function (index, el) {
				$(el)
					.removeClass('checkbox__label--checked')
					.find('input')
					.prop('checked', false);
			});
		} else {
			$labelAllArray.each(function (index, el) {
				$(el)
					.addClass('checkbox__label--checked')
					.find('input')
					.prop('checked', true);
			});
		}
	} else {
		$label.toggleClass('checkbox__label--checked');
		let $modifyArray = $labelAllArray.filter(function (i, el) {
			return !$(el).hasClass('checkbox__label--all');
		});
		var allChecked = true;
		$modifyArray.each(function (index, el) {
			if (!$(el).hasClass('checkbox__label--checked')) {
				allChecked = false;
			}
		});
		if (allChecked) {
			$label
				.siblings('.checkbox__label--all')
				.addClass('checkbox__label--checked')
				.find('input')
				.prop('checked', true);
		} else {
			$label
				.siblings('.checkbox__label--all')
				.removeClass('checkbox__label--checked')
				.find('input')
				.prop('checked', false);
		}
	}
});
// RADIO

$(document).on('click', '.js-radio', function () {
	let $parentLabel = $(this).closest('.radio__label');
	if (!$parentLabel.hasClass('radio__label--checked')) {
		$parentLabel
			.closest('.radio')
			.find('.radio__label--checked')
			.removeClass('radio__label--checked');
		$parentLabel
			.addClass('radio__label--checked');
	}
});

// SELECT

$('.js-select')
	// .outerWidth('100%')
	.select2({
		theme: 'filled',
		minimumResultsForSearch: Infinity
	});

$('.js-select--search')
	// .outerWidth('100%')
	.select2({
		language: {
			noResults: function () {
				return 'Ничего не найдено...';
			}
		},
		escapeMarkup: function (markup) {
			return markup;
		}
	});

$('.form__input--flat, .form__textarea--flat')
	.on('focus', function () {
		var $this = $(this);

		$this.closest('.form__label').addClass('form__label--focused');
	})
	.on('focusout', function () {
		var $this = $(this);

		if ($this.val() == '') {
			$this.closest('.form__label').removeClass('form__label--focused');
		}
	});

if ($('html[lang="en"]').length) {
	var PikaLang = {
		previousMonth: 'Prev. month',
		nextMonth: 'Next. month',
		months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
		weekdays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
		weekdaysShort: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']
	};
} else {
	var PikaLang = {
		previousMonth: 'Пред. месяц',
		nextMonth: 'След. месяц',
		months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
		weekdays: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
		weekdaysShort: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб']
	};
}

global.initPikadayGroup = function (group) {
	var $this = $(group);
	var startDate,
		endDate,
		updateStartDate = function () {
			startPicker.setStartRange(startDate);
			endPicker.setStartRange(startDate);
			endPicker.setMinDate(startDate);
		},
		updateEndDate = function () {
			startPicker.setEndRange(endDate);
			startPicker.setMaxDate(endDate);
			endPicker.setEndRange(endDate);
		},
		startPicker = new Pikaday({
			field: $this.find('.js-datepickerFrom')[0],
			i18n: PikaLang,
			minDate: new Date('1920-01-01'),
			maxDate: new Date(),
			format: 'DD.MM.YYYY',
			onSelect: function () {
				startDate = this.getDate();
				updateStartDate();
			}
		}),
		endPicker = new Pikaday({
			field: $this.find('.js-datepickerTo')[0],
			i18n: PikaLang,
			minDate: new Date('1920-01-01'),
			maxDate: new Date(),
			format: 'DD.MM.YYYY',
			onSelect: function () {
				endDate = this.getDate();
				updateEndDate();
			}
		}),
		_startDate = startPicker.getDate(),
		_endDate = endPicker.getDate();
	if (_startDate) {
		startDate = _startDate;
		updateStartDate();
	}
	if (_endDate) {
		endDate = _endDate;
		updateEndDate();
	}

	$('.search-results').on('click', '.js-settings-select-period', function (event) {
		event.preventDefault();
		let dateFrom = $(this).attr('data-from'),
			dateTo = $(this).attr('data-to');
		if (dateTo > moment().format('DD.MM.YYYY')) {
			dateTo = moment().format('DD.MM.YYYY');
		}
		$('#date-from').val(dateFrom);
		startPicker.setMoment(moment(dateFrom, 'DD.MM.YYYY'));
		$('#date-to').val(dateTo);
		endPicker.setMoment(moment(dateTo, 'DD.MM.YYYY'));
	});
};

if ($('.js-pikaday-group').length) {
	initPikadayGroup('.js-pikaday-group');
}

global.getJson = function (url, mimeType = false) {
	return new Promise(function (resolve, reject) {
		var xhr = new XMLHttpRequest();

		xhr.open('GET', url, true);
		// xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');
		if (mimeType) {
			xhr.overrideMimeType(mimeType);
		}

		xhr.onload = function () {
			if (this.status == 200) {
				resolve(this.response);
				// console.log(`Запрос вернулся с ответом отсюда - ${url}`);
			} else {
				var error = new Error(this.statusText);
				error.code = this.status;
				reject(error);
			}
		};

		xhr.onerror = function () {
			showNotyfications('Network Error', {type: 'error'});
			reject(new Error('Network Error'));
		};

		xhr.send();
	});
};

global.getUrlParam = function (name, url) {
	if (!url) {
		url = location.href;
	}
	name = name.replace(/[\[]/, '\\\[').replace(/[\]]/, '\\\]');
	var regexS = '[\\?&]' + name + '=([^&#]*)';
	var regex = new RegExp(regexS);
	var results = regex.exec(url);
	return results == null ? null : results[1];
};

global.getLang = function () {
	return $('html').attr('lang');
};

global.localize = function (obj) {
	if (typeof obj === 'object') {
		return obj[$('html').attr('lang')];
	}
};

global.downloadFile = function(sUrl) {
		//iOS devices do not support downloading. We have to inform user about this.
		// if (/(iP)/g.test(navigator.userAgent)) {
		// 		alert('Your device does not support files downloading. Please try again in desktop browser.');
		// 		return false;
		// }

		//If in Chrome or Safari - download via virtual link click
		if (window.downloadFile.isChrome || window.downloadFile.isSafari) {
				//Creating new link node.
				var link = document.createElement('a');
				link.href = sUrl;

				if (link.download !== undefined) {
						//Set HTML5 download attribute. This will prevent file from opening if supported.
						var fileName = sUrl.substring(sUrl.lastIndexOf('/') + 1, sUrl.length);
						link.download = fileName;
				}

				//Dispatching click event.
				if (document.createEvent) {
						var e = document.createEvent('MouseEvents');
						e.initEvent('click', true, true);
						link.dispatchEvent(e);
						return true;
				}
		}

		// Force file download (whether supported by server).
		if (sUrl.indexOf('?') === -1) {
				sUrl += '?download';
		}

		window.open(sUrl, '_self');
		return true;
}

window.downloadFile.isChrome = navigator.userAgent.toLowerCase().indexOf('chrome') > -1;
window.downloadFile.isSafari = navigator.userAgent.toLowerCase().indexOf('safari') > -1;

global.decimalLength = function(num) {
	var match = (''+num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
	if (!match) { return 0; }
	return Math.max(
			 0,
			 // Number of digits right of decimal point.
			 (match[1] ? match[1].length : 0)
			 // Adjust for scientific notation.
			 - (match[2] ? +match[2] : 0));
}

global.getCoords = function (address) {
	var res = request('GET', `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=AIzaSyA2eHAzlmXxIBBwsvP5oFCIlOJw_fXZ4cI`);
	var data = JSON.parse(res.getBody());

	if (data.results.length > 0) {
		var coords = data.results[0].geometry.location;
		coords.lat = parseFloat(coords.lat.toFixed(3));
		coords.lng = parseFloat(coords.lng.toFixed(3));
		return [coords.lng, coords.lat];
	} else {
		var msg = 'Coordingates not found for address: ' + address;
		console.error(msg);
	}
};

import {setGeoAside} from './../geolocation/geolocation.js';
import {setMinHeight} from './../main-slider/main-slider.js';

// INVESTOR TOOLS

// function setHeightFrameEuroLand() {
// 	let $iframe = $('.iframe--euroland');

// 	$iframe.each(function () {
// 		let height = $(window).outerHeight() - $(this).offset().top - $('.layout__main > .content').outerHeight() / 2;
// 		if (height > 340) {
// 			$(this).css({
// 				'height': `${height}px`
// 			})
// 		}
// 	});
// }

// if ($('.iframe--euroland').length) {
// 	setHeightFrameEuroLand();
// }

// $(window).resize(
// 	throttle(300, function () {
// 		setHeightFrameEuroLand();
// 	})
// );

// for debug
window.onload = function () {
	setTimeout(objectFitPolyfill, 1000);
	setGeoAside();
	setMinHeight();


	// if ($('.iframe--euroland').length) {
	// 	setTimeout(setHeightFrameEuroLand, 1000);
	// }
};
