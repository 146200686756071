let triggerName;

if ($('html').hasClass('bx-touch')) {
	triggerName = 'click';
} else {
	triggerName = 'hover';
}

$('.tooltip').tooltipster({
	maxWidth: 500,
	speed: 300,
	interactive: true,
	theme: ['tooltipster-light'],
	animation: 'grow',
	trigger: triggerName,
	functionPosition: function (instance, helper, position) {
		// check aside
		if ((position.coord.left <= $('.layout__aside').outerWidth()) && !inWidth(768)) {
			let offsetLeft = $('.layout__aside').outerWidth() - position.coord.left;
			position.coord.left += offsetLeft + 32;
		} else if (position.coord.left <= 32) {
			position.coord.left = 32;
		}

		if (position.size.width >= ($(window).innerWidth() - 32 * 2)) {
			let changeHeight = position.size.height * 1.2 - position.size.height;
			position.size.width = $(window).innerWidth() - 32 * 2;
			position.size.height += changeHeight;
			position.coord.top -= changeHeight; 
		}
		return position;
	}
});

$('.js-hack-link').on('click', function () {
	window.open($(this).data('href'), '_blank');
});

$('.tooltip').each(function () {
	if ($(this).outerWidth() >= 280) {
		console.log($(this));
		$(this).css('display', 'inline');
	}
});
