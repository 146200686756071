const verge = require('verge');
const throttle = require('throttle-debounce/throttle');
// include ECharts main module
const echarts = require('echarts/lib/echarts');
// include pie, line chart
require('echarts/lib/chart/line');
require('echarts/lib/chart/pie');
require('echarts/lib/chart/bar');
// include components
require('echarts/lib/component/tooltip');
require('echarts/lib/component/grid');
require('echarts/lib/component/legend');

import {ratingsLegendY, ratingsLegendX} from './ratings-legends.js';
import {chartsTheme} from './theme.js';
let creditRatings = document.getElementById('credit-ratings');
let creditRating1 = document.getElementById('credit-rating-1');
let creditRating2 = document.getElementById('credit-rating-2');
let creditRating3 = document.getElementById('credit-rating-3');
let grCapital1 = document.getElementById('gr-capital-1');
let grCapital2 = document.getElementById('gr-capital-2');
let grLiquidity1 = document.getElementById('gr-liquidity-1');
let grLiquidity2 = document.getElementById('gr-liquidity-2');
let grLiquidity3 = document.getElementById('gr-liquidity-3');
let grLiquidity4 = document.getElementById('gr-liquidity-4');
let grLiquidity5 = document.getElementById('gr-liquidity-5');
let bm1 = document.getElementById('bm-1');
let bm2 = document.getElementById('bm-2');
let bm3 = document.getElementById('bm-3');
let bm4 = document.getElementById('bm-4');
let bm5 = document.getElementById('bm-5');
let grStrategy1 = document.getElementById('strategy-chart-1');
let grStrategy2 = document.getElementById('strategy-chart-2');

if (creditRatings) {
	let creditRatingsChart = echarts.init(creditRatings, chartsTheme);

	creditRatingsChart
		.setOption({
			title: {
				show: false
			},
			tooltip: {
				trigger: 'axis'
			},
			legend: {
				data: [`Fitch Ratings`, `Standard & Poor's`, `Moody's`]
			},
			grid: {
				left: '0',
				right: '0',
				bottom: '0',
				containLabel: true
			},
			xAxis: {
				type: 'category',
				data: localize({
						ru: [
							'август 2004',
							'август 2005',
							'октябрь 2005',
							'декабрь 2005',
							'август 2006',
							'октябрь 2006',
							'ноябрь 2006',
							'июнь 2007',
							'август 2007',
							'декабрь 2007',
							'март 2008',
							'август 2008',
							'февраль 2009',
							'март 2009',
							'август 2009',
							'ноябрь 2009',
							'июль 2010',
							'декабрь 2010',
							'февраль 2011',
							'март 2011',
							'май 2011',
							'август 2011',
							'сентябрь 2011',
							'июль 2012',
							'август 2012',
							'апрель 2013',
							'октябрь 2013',
							'апрель 2014',
							'июль 2014',
							'октябрь 2014',
							'декабрь 2014',
							'январь 2015',
							'февраль 2015',
							'июль 2015',
							'декабрь 2015',
							'январь 2016',
							'апрель 2016',
							'сентябрь 2016',
							'октябрь 2016',
							'февраль 2017'
						],
						en: [
							'August 2004',
							'August 2005',
							'October 2005',
							'December 2005',
							'August 2006',
							'October 2006',
							'November 2006',
							'June 2007',
							'August 2007',
							'December 2007',
							'March 2008',
							'August 2008',
							'February 2009',
							'March 2009',
							'August 2009',
							'November 2009',
							'July 2010',
							'December 2010',
							'February 2011',
							'March 2011',
							'May 2011',
							'August 2011',
							'Сентябрь 2011',
							'July 2012',
							'August 2012',
							'April 2013',
							'October 2013',
							'April 2014',
							'July 2014',
							'October 2014',
							'December 2014',
							'January 2015',
							'February 2015',
							'July 2015',
							'December 2015',
							'January 2016',
							'April 2016',
							'September 2016',
							'October 2016',
							'February 2017'
						]
					})
			},
			yAxis: {
				type: 'category',
				data: ['B-/B3', 'B/B2', 'B+/B1', 'BB-/Ba3', 'BB/Ba2', 'BB+/Ba1', 'BBB-/Baa3', 'BBB/Baa2', 'AA-/Aa3']
			},
			series: [
				{
					name: `Fitch Ratings`,
					type: 'line',
					step: 'middle',
					data: localize({
						ru: [
									[
										'октябрь 2016',
										'BBB-/Baa3'
									],
									[
										'июль 2015',
										'BBB-/Baa3'
									],
									[
										'январь 2015',
										'BBB-/Baa3'
									],
									[
										'июль 2014',
										'BBB-/Baa3'
									],
									[
										'апрель 2013',
										'BB+/Ba1'
									],
									[
										'август 2012',
										'BB+/Ba1'
									],
									[
										'август 2011',
										'BB+/Ba1'
									],
									[
										'март 2011',
										'BBB-/Baa3'
									],
									[
										'ноябрь 2009',
										'BBB-/Baa3'
									],
									[
										'август 2009',
										'BBB-/Baa3'
									],
									[
										'март 2009',
										'BBB-/Baa3'
									],
									[
										'август 2007',
										'BBB-/Baa3'
									],
									[
										'июнь 2007',
										'BBB-/Baa3'
									],
									[
										'ноябрь 2006',
										'BBB-/Baa3'
									],
									[
										'декабрь 2005',
										'BBB-/Baa3'
									]
								],
						en: [
							[
								'October 2016',
								'BBB-/Baa3'
							],
							[
								'July 2015',
								'BBB-/Baa3'
							],
							[
								'January 2015',
								'BBB-/Baa3'
							],
							[
								'July 2014',
								'BBB-/Baa3'
							],
							[
								'April 2013',
								'BB+/Ba1'
							],
							[
								'August 2012',
								'BB+/Ba1'
							],
							[
								'August 2011',
								'BB+/Ba1'
							],
							[
								'March 2011',
								'BBB-/Baa3'
							],
							[
								'November 2009',
								'BBB-/Baa3'
							],
							[
								'August 2009',
								'BBB-/Baa3'
							],
							[
								'March 2009',
								'BBB-/Baa3'
							],
							[
								'August 2007',
								'BBB-/Baa3'
							],
							[
								'June 2007',
								'BBB-/Baa3'
							],
							[
								'November 2006',
								'BBB-/Baa3'
							],
							[
								'December 2005',
								'BBB-/Baa3'
							]
						]
					})
				},
				{
					name: `Standard & Poor's`,
					type: 'line',
					step: 'middle',
					data: localize({
						ru: [
									[
										'октябрь 2016',
										'BBB-/Baa3'
									],
									[
										'сентябрь 2016',
										'BBB-/Baa3'
									],
									[
										'декабрь 2014',
										'BBB-/Baa3'
									],
									[
										'апрель 2014',
										'BBB-/Baa3'
									],
									[
										'октябрь 2013',
										'BBB-/Baa3'
									],
									[
										'апрель 2013',
										'BBB-/Baa3'
									],
									[
										'сентябрь 2011',
										'BBB-/Baa3'
									],
									[
										'май 2011',
										'BBB-/Baa3'
									],
									[
										'февраль 2011',
										'BBB-/Baa3'
									],
									[
										'декабрь 2010',
										'BBB-/Baa3'
									],
									[
										'июль 2010',
										'BBB-/Baa3'
									],
									[
										'февраль 2009',
										'BBB-/Baa3'
									],
									[
										'август 2008',
										'BBB-/Baa3'
									],
									[
										'март 2008',
										'BBB-/Baa3'
									],
									[
										'декабрь 2007',
										'BBB-/Baa3'
									],
									[
										'август 2006',
										'BBB-/Baa3'
									],
									[
										'август 2005',
										'BB+/Ba1'
									],
									[
										'август 2004',
										'BB/Ba2'
									]
								],
						en: [
									[
										'October 2016',
										'BBB-/Baa3'
									],
									[
										'September 2016',
										'BBB-/Baa3'
									],
									[
										'December 2014',
										'BBB-/Baa3'
									],
									[
										'April 2014',
										'BBB-/Baa3'
									],
									[
										'October 2013',
										'BBB-/Baa3'
									],
									[
										'April 2013',
										'BBB-/Baa3'
									],
									[
										'September 2011',
										'BBB-/Baa3'
									],
									[
										'May 2011',
										'BBB-/Baa3'
									],
									[
										'February 2011',
										'BBB-/Baa3'
									],
									[
										'December 2010',
										'BBB-/Baa3'
									],
									[
										'July 2010',
										'BBB-/Baa3'
									],
									[
										'February 2009',
										'BBB-/Baa3'
									],
									[
										'August 2008',
										'BBB-/Baa3'
									],
									[
										'March 2008',
										'BBB-/Baa3'
									],
									[
										'December 2007',
										'BBB-/Baa3'
									],
									[
										'August 2006',
										'BBB-/Baa3'
									],
									[
										'August 2005',
										'BB+/Ba1'
									],
									[
										'August 2004',
										'BB/Ba2'
									]
								]
					})
				},
				{
					name: `Moody's`,
					type: 'line',
					step: 'middle',
					data: localize({
						ru: [
									[
										'февраль 2017',
										'BB+/Ba1'
									],
									[
										'апрель 2016',
										'BB+/Ba1'
									],
									[
										'январь 2016',
										'BB+/Ba1'
									],
									[
										'декабрь 2015',
										'BB+/Ba1'
									],
									[
										'февраль 2015',
										'BB+/Ba1'
									],
									[
										'январь 2015',
										'BBB-/Baa3'
									],
									[
										'декабрь 2014',
										'BBB/Baa2'
									],
									[
										'октябрь 2014',
										'BBB/Baa2'
									],
									[
										'апрель 2013',
										'BBB/Baa2'
									],
									[
										'июль 2012',
										'BBB/Baa2'
									],
									[
										'июнь 2007',
										'BBB/Baa2'
									],
									[
										'октябрь 2006',
										'BBB/Baa2'
									],
									[
										'октябрь 2005',
										'BB+/Ba1'
									],
									[
										'август 2004',
										'BB+/Ba1'
									]
								],
						en: [
									[
										'February 2017',
										'BB+/Ba1'
									],
									[
										'April 2016',
										'BB+/Ba1'
									],
									[
										'January 2016',
										'BB+/Ba1'
									],
									[
										'December 2015',
										'BB+/Ba1'
									],
									[
										'February 2015',
										'BB+/Ba1'
									],
									[
										'January 2015',
										'BBB-/Baa3'
									],
									[
										'December 2014',
										'BBB/Baa2'
									],
									[
										'October 2014',
										'BBB/Baa2'
									],
									[
										'April 2013',
										'BBB/Baa2'
									],
									[
										'July 2012',
										'BBB/Baa2'
									],
									[
										'June 2007',
										'BBB/Baa2'
									],
									[
										'October 2006',
										'BBB/Baa2'
									],
									[
										'October 2005',
										'BB+/Ba1'
									],
									[
										'August 2004',
										'BB+/Ba1'
									]
								]
					})
				}
			]
		});

	$(window).on('resize', function () {
		if (creditRatingsChart != null && creditRatingsChart != undefined) {
			creditRatingsChart.resize();
		}
	});
}

if (creditRating1) {
	let creditRating1Chart = echarts.init(creditRating1, chartsTheme);

	creditRating1Chart
		.setOption({
			title: {
				show: false
			},
			tooltip: {
				trigger: 'axis',
				formatter: function (value, index) {
					let newValue = `${ratingsLegendX[value[0].axisValue.substr(0, value[0].axisValue.indexOf('!'))]} ${value[0].axisValue.substr(value[0].axisValue.indexOf('!') + 1, value.length + 2)}`;
					value.forEach(function(el, i, chartsArr) {
						let val = ratingsLegendY[el.value[1]][0],
								valLast = val[val.length - 1],
								valStr = val.substr(0, val.length - 1),
								text;
						if (valLast == 'S') {
							text = localize({
								ru: 'стабильн',
								en: 'stable'
							})
						} else if (valLast == '+') {
							text = localize({
								ru: 'положит',
								en: 'positive'
							})
						} else if (valLast == '-') {
							text = localize({
								ru: 'отрицат',
								en: 'negative'
							})
						}
						valStr = valStr + text;
						newValue += '<br><span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:' + el.color +';"></span>' + el.seriesName + ': ' + valStr;
					})

					return `${newValue}`;
				}
			},
			legend: {
				data: [
					localize({
						ru: `Рейтинг НН`,
						en: 'Nornickel'
					}),
					localize({
						ru: `Рейтинг РФ`,
						en: 'Russia'
					})
				]
			},
			grid: {
				left: '0',
				right: '2%',
				bottom: '20',
				containLabel: true
			},
			xAxis: {
				type: 'category',
				splitLine: {
						show: false
				},
				axisLabel: {
					formatter: function (value, index) {
						// return `${ratingsLegendX[value.substr(0, value.indexOf('!'))]} ${value.substr(value.indexOf('!') + 1, value.length)}`;
						return value.substr(value.indexOf('!') + 1, value.length); 
					},
					interval: 11,
					rotate: 90
				},
				data: [
					'1!2004',
					'2!2004',
					'3!2004',
					'4!2004',
					'5!2004',
					'6!2004',
					'7!2004',
					'8!2004',
					'9!2004',
					'10!2004',
					'11!2004',
					'12!2004',
					'1!2005',
					'2!2005',
					'3!2005',
					'4!2005',
					'5!2005',
					'6!2005',
					'7!2005',
					'8!2005',
					'9!2005',
					'10!2005',
					'11!2005',
					'12!2005',
					'1!2006',
					'2!2006',
					'3!2006',
					'4!2006',
					'5!2006',
					'6!2006',
					'7!2006',
					'8!2006',
					'9!2006',
					'10!2006',
					'11!2006',
					'12!2006',
					'1!2007',
					'2!2007',
					'3!2007',
					'4!2007',
					'5!2007',
					'6!2007',
					'7!2007',
					'8!2007',
					'9!2007',
					'10!2007',
					'11!2007',
					'12!2007',
					'1!2008',
					'2!2008',
					'3!2008',
					'4!2008',
					'5!2008',
					'6!2008',
					'7!2008',
					'8!2008',
					'9!2008',
					'10!2008',
					'11!2008',
					'12!2008',
					'1!2009',
					'2!2009',
					'3!2009',
					'4!2009',
					'5!2009',
					'6!2009',
					'7!2009',
					'8!2009',
					'9!2009',
					'10!2009',
					'11!2009',
					'12!2009',
					'1!2010',
					'2!2010',
					'3!2010',
					'4!2010',
					'5!2010',
					'6!2010',
					'7!2010',
					'8!2010',
					'9!2010',
					'10!2010',
					'11!2010',
					'12!2010',
					'1!2011',
					'2!2011',
					'3!2011',
					'4!2011',
					'5!2011',
					'6!2011',
					'7!2011',
					'8!2011',
					'9!2011',
					'10!2011',
					'11!2011',
					'12!2011',
					'1!2012',
					'2!2012',
					'3!2012',
					'4!2012',
					'5!2012',
					'6!2012',
					'7!2012',
					'8!2012',
					'9!2012',
					'10!2012',
					'11!2012',
					'12!2012',
					'1!2013',
					'2!2013',
					'3!2013',
					'4!2013',
					'5!2013',
					'6!2013',
					'7!2013',
					'8!2013',
					'9!2013',
					'10!2013',
					'11!2013',
					'12!2013',
					'1!2014',
					'2!2014',
					'3!2014',
					'4!2014',
					'5!2014',
					'6!2014',
					'7!2014',
					'8!2014',
					'9!2014',
					'10!2014',
					'11!2014',
					'12!2014',
					'1!2015',
					'2!2015',
					'3!2015',
					'4!2015',
					'5!2015',
					'6!2015',
					'7!2015',
					'8!2015',
					'9!2015',
					'10!2015',
					'11!2015',
					'12!2015',
					'1!2016',
					'2!2016',
					'3!2016',
					'4!2016',
					'5!2016',
					'6!2016',
					'7!2016',
					'8!2016',
					'9!2016',
					'10!2016',
					'11!2016',
					'12!2016',
					'1!2017',
					'2!2017',
					'3!2017',
					'4!2017',
					'5!2017',
					'6!2017',
					'7!2017',
					'8!2017',
					'9!2017',
					'10!2017',
					'11!2017',
					'12!2017',
					'1!2018',
					'2!2018',
					'3!2018',
					'4!2018',
					'5!2018',
					'6!2018',
					'7!2018',
					'8!2018',
					'9!2018',
					'10!2018',
					'11!2018',
					'12!2018'
				]
			},
			yAxis: {
				type: 'value',
				data: [9.5, 9, 8.5, 8, 7.5, 7, 6.5, 6, 5.5, 5, 4.5, 4, 3.5, 3, 2.5],
				splitNumber: 14,
				min: 2.5,
				max: 9.5,
				axisLabel: {
					formatter: function (value, index) {
						let newVal = ratingsLegendY[value][0];
						if (newVal[newVal.length - 1] !== 'S') {
							newVal = newVal.substr(newVal.length - 1);
						}
						return newVal;
					},
					fontSize: 14
				}
			},
			series: [
				{
					name: localize({
						ru: `Рейтинг РФ`,
						en: 'Russia'
					}),
					type: 'line',
					step: 'middle',
					showSymbol: false,
					data: [
						['8!2004', 6.00],
						['9!2004', 6.00],
						['10!2004', 6.00],
						['11!2004', 6.00],
						['12!2004', 6.00],
						['1!2005', 6.00],
						['2!2005', 6.00],
						['3!2005', 6.00],
						['4!2005', 6.00],
						['5!2005', 6.00],
						['6!2005', 6.00],
						['7!2005', 6.00],
						['8!2005', 6.00],
						['9!2005', 6.50],
						['10!2005', 7.50],
						['11!2005', 7.50],
						['12!2005', 7.50],
						['1!2006', 7.50],
						['2!2006', 7.50],
						['3!2006', 7.50],
						['4!2006', 7.50],
						['5!2006', 7.50],
						['6!2006', 7.50],
						['7!2006', 7.50],
						['8!2006', 7.50],
						['9!2006', 7.50],
						['10!2006', 7.50],
						['11!2006', 7.50],
						['12!2006', 7.50],
						['1!2007', 7.50],
						['2!2007', 7.50],
						['3!2007', 7.50],
						['4!2007', 7.50],
						['5!2007', 7.50],
						['6!2007', 7.50],
						['7!2007', 7.50],
						['8!2007', 7.50],
						['9!2007', 7.50],
						['10!2007', 7.50],
						['11!2007', 7.50],
						['12!2007', 7.50],
						['1!2008', 7.50],
						['2!2008', 7.50],
						['3!2008', 8.00],
						['4!2008', 8.00],
						['5!2008', 8.00],
						['6!2008', 8.00],
						['7!2008', 9.00],
						['8!2008', 9.00],
						['9!2008', 9.00],
						['10!2008', 9.00],
						['11!2008', 9.00],
						['12!2008', 9.00],
						['1!2009', 9.00],
						['2!2009', 9.00],
						['3!2009', 9.00],
						['4!2009', 9.00],
						['5!2009', 9.00],
						['6!2009', 9.00],
						['7!2009', 9.00],
						['8!2009', 9.00],
						['9!2009', 9.00],
						['10!2009', 9.00],
						['11!2009', 9.00],
						['12!2009', 9.00],
						['1!2010', 9.00],
						['2!2010', 9.00],
						['3!2010', 9.00],
						['4!2010', 9.00],
						['5!2010', 9.00],
						['6!2010', 9.00],
						['7!2010', 9.00],
						['8!2010', 9.00],
						['9!2010', 9.00],
						['10!2010', 9.00],
						['11!2010', 9.00],
						['12!2010', 9.00],
						['1!2011', 9.00],
						['2!2011', 9.00],
						['3!2011', 9.00],
						['4!2011', 9.00],
						['5!2011', 9.00],
						['6!2011', 9.00],
						['7!2011', 9.00],
						['8!2011', 9.00],
						['9!2011', 9.00],
						['10!2011', 9.00],
						['11!2011', 9.00],
						['12!2011', 9.00],
						['1!2012', 9.00],
						['2!2012', 9.00],
						['3!2012', 9.00],
						['4!2012', 9.00],
						['5!2012', 9.00],
						['6!2012', 9.00],
						['7!2012', 9.00],
						['8!2012', 9.00],
						['9!2012', 9.00],
						['10!2012', 9.00],
						['11!2012', 9.00],
						['12!2012', 9.00],
						['1!2013', 9.00],
						['2!2013', 9.00],
						['3!2013', 9.00],
						['4!2013', 9.00],
						['5!2013', 9.00],
						['6!2013', 9.00],
						['7!2013', 9.00],
						['8!2013', 9.00],
						['9!2013', 9.00],
						['10!2013', 9.00],
						['11!2013', 9.00],
						['12!2013', 9.00],
						['1!2014', 9.00],
						['2!2014', 9.00],
						['3!2014', 8.50],
						['4!2014', 8.50],
						['5!2014', 8.50],
						['6!2014', 8.50],
						['7!2014', 8.50],
						['8!2014', 8.50],
						['9!2014', 8.50],
						['10!2014', 7.00],
						['11!2014', 7.00],
						['12!2014', 7.00],
						['1!2015', 5.50],
						['2!2015', 4.00],
						['3!2015', 4.00],
						['4!2015', 4.00],
						['5!2015', 4.00],
						['6!2015', 4.00],
						['7!2015', 4.00],
						['8!2015', 4.00],
						['9!2015', 4.00],
						['10!2015', 4.00],
						['11!2015', 4.00],
						['12!2015', 4.00],
						['1!2016', 4.00],
						['2!2016', 4.00],
						['3!2016', 4.00],
						['4!2016', 4.00],
						['5!2016', 4.00],
						['6!2016', 4.00],
						['7!2016', 4.00],
						['8!2016', 4.00],
						['9!2016', 4.00],
						['10!2016', 4.00],
						['11!2016', 4.00],
						['12!2016', 4.00],
						['1!2017', 4.00],
						['2!2017', 4.50],
						['3!2017', 4.50],
						['4!2017', 4.50],
						['5!2017', 4.50],
						['6!2017', 4.50],
						['7!2017', 4.50],
						['8!2017', 4.50],
						['9!2017', 4.50],
						['10!2017', 4.50],
						['11!2017', 4.50],
						['12!2017', 4.50],
						['1!2018', 5.00]
					]
				},
				{
					name: localize({
						ru: `Рейтинг НН`,
						en: 'Nornickel'
					}),
					type: 'line',
					step: 'middle',
					showSymbol: false,
					data: [
						['8!2004', 4.50],
						['9!2004', 4.50],
						['10!2004', 4.50],
						['11!2004', 4.50],
						['12!2004', 4.50],
						['1!2005', 4.50],
						['2!2005', 4.50],
						['3!2005', 4.50],
						['4!2005', 4.50],
						['5!2005', 4.50],
						['6!2005', 4.50],
						['7!2005', 4.50],
						['8!2005', 4.50],
						['9!2005', 4.50],
						['10!2005', 4.50],
						['11!2005', 4.50],
						['12!2005', 4.50],
						['1!2006', 4.50],
						['2!2006', 4.50],
						['3!2006', 4.50],
						['4!2006', 4.50],
						['5!2006', 4.50],
						['6!2006', 4.50],
						['7!2006', 4.50],
						['8!2006', 5.00],
						['9!2006', 5.00],
						['10!2006', 7.50],
						['11!2006', 7.50],
						['12!2006', 7.50],
						['1!2007', 7.50],
						['2!2007', 7.50],
						['3!2007', 7.50],
						['4!2007', 7.50],
						['5!2007', 7.50],
						['6!2007', 7.50],
						['7!2007', 7.50],
						['8!2007', 7.50],
						['9!2007', 7.50],
						['10!2007', 7.50],
						['11!2007', 7.50],
						['12!2007', 7.50],
						['1!2008', 7.50],
						['2!2008', 7.50],
						['3!2008', 7.50],
						['4!2008', 7.50],
						['5!2008', 7.50],
						['6!2008', 7.50],
						['7!2008', 7.50],
						['8!2008', 7.50],
						['9!2008', 7.50],
						['10!2008', 7.50],
						['11!2008', 7.50],
						['12!2008', 7.50],
						['1!2009', 7.50],
						['2!2009', 7.50],
						['3!2009', 7.50],
						['4!2009', 7.50],
						['5!2009', 7.50],
						['6!2009', 7.50],
						['7!2009', 7.50],
						['8!2009', 7.50],
						['9!2009', 7.50],
						['10!2009', 7.50],
						['11!2009', 7.50],
						['12!2009', 7.50],
						['1!2010', 7.50],
						['2!2010', 7.50],
						['3!2010', 7.50],
						['4!2010', 7.50],
						['5!2010', 7.50],
						['6!2010', 7.50],
						['7!2010', 7.50],
						['8!2010', 7.50],
						['9!2010', 7.50],
						['10!2010', 7.50],
						['11!2010', 7.50],
						['12!2010', 7.50],
						['1!2011', 7.50],
						['2!2011', 7.50],
						['3!2011', 7.50],
						['4!2011', 7.50],
						['5!2011', 7.50],
						['6!2011', 7.50],
						['7!2011', 7.50],
						['8!2011', 7.00],
						['9!2011', 7.00],
						['10!2011', 7.00],
						['11!2011', 7.00],
						['12!2011', 7.00],
						['1!2012', 7.00],
						['2!2012', 7.00],
						['3!2012', 7.00],
						['4!2012', 7.00],
						['5!2012', 7.00],
						['6!2012', 7.00],
						['7!2012', 7.50],
						['8!2012', 7.50],
						['9!2012', 7.50],
						['10!2012', 7.50],
						['11!2012', 7.50],
						['12!2012', 7.50],
						['1!2013', 7.50],
						['2!2013', 7.50],
						['3!2013', 7.50],
						['4!2013', 7.50],
						['5!2013', 7.50],
						['6!2013', 7.50],
						['7!2013', 7.50],
						['8!2013', 7.50],
						['9!2013', 7.50],
						['10!2013', 7.50],
						['11!2013', 7.50],
						['12!2013', 7.50],
						['1!2014', 7.50],
						['2!2014', 7.50],
						['3!2014', 7.50],
						['4!2014', 7.50],
						['5!2014', 7.50],
						['6!2014', 7.50],
						['7!2014', 7.50],
						['8!2014', 7.50],
						['9!2014', 7.50],
						['10!2014', 7.50],
						['11!2014', 7.50],
						['12!2014', 7.00],
						['1!2015', 5.50],
						['2!2015', 4.00],
						['3!2015', 4.00],
						['4!2015', 4.00],
						['5!2015', 4.00],
						['6!2015', 4.00],
						['7!2015', 4.00],
						['8!2015', 4.00],
						['9!2015', 4.00],
						['10!2015', 4.00],
						['11!2015', 4.00],
						['12!2015', 4.00],
						['1!2016', 4.00],
						['2!2016', 4.00],
						['3!2016', 4.00],
						['4!2016', 4.00],
						['5!2016', 4.00],
						['6!2016', 4.00],
						['7!2016', 4.00],
						['8!2016', 4.00],
						['9!2016', 4.00],
						['10!2016', 4.00],
						['11!2016', 4.00],
						['12!2016', 4.00],
						['1!2017', 4.00],
						['2!2017', 4.50],
						['3!2017', 4.50],
						['4!2017', 4.50],
						['5!2017', 4.50],
						['6!2017', 4.50],
						['7!2017', 4.50],
						['8!2017', 4.50],
						['9!2017', 4.50],
						['10!2017', 4.50],
						['11!2017', 4.50],
						['12!2017', 4.50],
						['1!2018', 6.50]
					]
				}
			],
			color: ['#cb2222', '#005a9d']
		});

	$(window).on('resize', function () {
		if (creditRating1Chart != null && creditRating1Chart != undefined) {
			creditRating1Chart.resize();
		}
	});
}

if (creditRating2) {
	let creditRating2Chart = echarts.init(creditRating2, chartsTheme);

	creditRating2Chart
		.setOption({
			title: {
				show: false
			},
			tooltip: {
				trigger: 'axis',
				formatter: function (value, index) {
					let newValue = `${ratingsLegendX[value[0].axisValue.substr(0, value[0].axisValue.indexOf('!'))]} ${value[0].axisValue.substr(value[0].axisValue.indexOf('!') + 1, value.length + 2)}`;
					value.forEach(function(el, i, chartsArr) {
						let val = ratingsLegendY[el.value[1]][1],
								valLast = val[val.length - 1],
								valStr = val.substr(0, val.length - 1),
								text;
						if (valLast == 'S') {
							text = localize({
								ru: 'стабильн',
								en: 'stable'
							})
						} else if (valLast == '+') {
							text = localize({
								ru: 'положит',
								en: 'positive'
							})
						} else if (valLast == '-') {
							text = localize({
								ru: 'отрицат',
								en: 'negative'
							})
						}
						valStr = valStr + text; 
						newValue += '<br><span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:' + el.color +';"></span>' + el.seriesName + ': ' + valStr;
					})
					return `${newValue}`;
				}
			},
			legend: {
				data: [
					localize({
						ru: `Рейтинг НН`,
						en: 'Nornickel'
					}),
					localize({
						ru: `Рейтинг РФ`,
						en: 'Russia'
					})
				]
			},
			grid: {
				left: '0',
				right: '2%',
				bottom: '20',
				containLabel: true
			},
			xAxis: {
				type: 'category',
				splitLine: {
						show: false
				},
				axisLabel: {
					formatter: function (value, index) {
						// return `${ratingsLegendX[value.substr(0, value.indexOf('!'))]} ${value.substr(value.indexOf('!') + 1, value.length)}`;
						return value.substr(value.indexOf('!') + 1, value.length);
					},
					interval: 11,
					rotate: 90
				},
				data: [
					'1!2004',
					'2!2004',
					'3!2004',
					'4!2004',
					'5!2004',
					'6!2004',
					'7!2004',
					'8!2004',
					'9!2004',
					'10!2004',
					'11!2004',
					'12!2004',
					'1!2005',
					'2!2005',
					'3!2005',
					'4!2005',
					'5!2005',
					'6!2005',
					'7!2005',
					'8!2005',
					'9!2005',
					'10!2005',
					'11!2005',
					'12!2005',
					'1!2006',
					'2!2006',
					'3!2006',
					'4!2006',
					'5!2006',
					'6!2006',
					'7!2006',
					'8!2006',
					'9!2006',
					'10!2006',
					'11!2006',
					'12!2006',
					'1!2007',
					'2!2007',
					'3!2007',
					'4!2007',
					'5!2007',
					'6!2007',
					'7!2007',
					'8!2007',
					'9!2007',
					'10!2007',
					'11!2007',
					'12!2007',
					'1!2008',
					'2!2008',
					'3!2008',
					'4!2008',
					'5!2008',
					'6!2008',
					'7!2008',
					'8!2008',
					'9!2008',
					'10!2008',
					'11!2008',
					'12!2008',
					'1!2009',
					'2!2009',
					'3!2009',
					'4!2009',
					'5!2009',
					'6!2009',
					'7!2009',
					'8!2009',
					'9!2009',
					'10!2009',
					'11!2009',
					'12!2009',
					'1!2010',
					'2!2010',
					'3!2010',
					'4!2010',
					'5!2010',
					'6!2010',
					'7!2010',
					'8!2010',
					'9!2010',
					'10!2010',
					'11!2010',
					'12!2010',
					'1!2011',
					'2!2011',
					'3!2011',
					'4!2011',
					'5!2011',
					'6!2011',
					'7!2011',
					'8!2011',
					'9!2011',
					'10!2011',
					'11!2011',
					'12!2011',
					'1!2012',
					'2!2012',
					'3!2012',
					'4!2012',
					'5!2012',
					'6!2012',
					'7!2012',
					'8!2012',
					'9!2012',
					'10!2012',
					'11!2012',
					'12!2012',
					'1!2013',
					'2!2013',
					'3!2013',
					'4!2013',
					'5!2013',
					'6!2013',
					'7!2013',
					'8!2013',
					'9!2013',
					'10!2013',
					'11!2013',
					'12!2013',
					'1!2014',
					'2!2014',
					'3!2014',
					'4!2014',
					'5!2014',
					'6!2014',
					'7!2014',
					'8!2014',
					'9!2014',
					'10!2014',
					'11!2014',
					'12!2014',
					'1!2015',
					'2!2015',
					'3!2015',
					'4!2015',
					'5!2015',
					'6!2015',
					'7!2015',
					'8!2015',
					'9!2015',
					'10!2015',
					'11!2015',
					'12!2015',
					'1!2016',
					'2!2016',
					'3!2016',
					'4!2016',
					'5!2016',
					'6!2016',
					'7!2016',
					'8!2016',
					'9!2016',
					'10!2016',
					'11!2016',
					'12!2016',
					'1!2017',
					'2!2017',
					'3!2017',
					'4!2017',
					'5!2017',
					'6!2017',
					'7!2017',
					'8!2017',
					'9!2017',
					'10!2017',
					'11!2017',
					'12!2017',
					'1!2018',
					'2!2018',
					'3!2018',
					'4!2018',
					'5!2018',
					'6!2018',
					'7!2018',
					'8!2018',
					'9!2018',
					'10!2018',
					'11!2018',
					'12!2018'
				]
			},
			yAxis: {
				type: 'value',
				data: [9.5, 9, 8.5, 8, 7.5, 7, 6.5, 6, 5.5, 5, 4.5, 4, 3.5, 3, 2.5],
				splitNumber: 14,
				min: 2.5,
				max: 9.5,
				axisLabel: {
					formatter: function (value, index) {
						let newVal = ratingsLegendY[value][1];
						if (newVal[newVal.length - 1] !== 'S') {
							newVal = newVal.substr(newVal.length - 1);
						}
						return newVal;
					},
					fontSize: 14
				}
			},
			series: [
				{
					name: localize({
						ru: `Рейтинг РФ`,
						en: 'Russia'
					}),
					type: 'line',
					step: 'middle',
					showSymbol: false,
					data: [
						['8!2004', 4.50],
						['9!2004', 4.50],
						['10!2004', 4.50],
						['11!2004', 4.50],
						['12!2004', 4.50],
						['1!2005', 6.00],
						['2!2005', 6.00],
						['3!2005', 6.00],
						['4!2005', 6.00],
						['5!2005', 6.00],
						['6!2005', 6.00],
						['7!2005', 6.00],
						['8!2005', 6.00],
						['9!2005', 6.00],
						['10!2005', 6.00],
						['11!2005', 6.00],
						['12!2005', 7.50],
						['1!2006', 7.50],
						['2!2006', 7.50],
						['3!2006', 7.50],
						['4!2006', 7.50],
						['5!2006', 7.50],
						['6!2006', 7.50],
						['7!2006', 7.50],
						['8!2006', 7.50],
						['9!2006', 9.00],
						['10!2006', 9.00],
						['11!2006', 9.00],
						['12!2006', 9.00],
						['1!2007', 9.00],
						['2!2007', 9.00],
						['3!2007', 9.00],
						['4!2007', 9.00],
						['5!2007', 9.00],
						['6!2007', 9.00],
						['7!2007', 9.00],
						['8!2007', 9.00],
						['9!2007', 9.00],
						['10!2007', 9.00],
						['11!2007', 9.00],
						['12!2007', 9.00],
						['1!2008', 9.00],
						['2!2008', 9.00],
						['3!2008', 9.50],
						['4!2008', 9.50],
						['5!2008', 9.50],
						['6!2008', 9.50],
						['7!2008', 9.50],
						['8!2008', 9.50],
						['9!2008', 9.00],
						['10!2008', 8.50],
						['11!2008', 8.50],
						['12!2008', 7.00],
						['1!2009', 7.00],
						['2!2009', 7.00],
						['3!2009', 7.00],
						['4!2009', 7.00],
						['5!2009', 7.00],
						['6!2009', 7.00],
						['7!2009', 7.00],
						['8!2009', 7.00],
						['9!2009', 7.00],
						['10!2009', 7.00],
						['11!2009', 7.00],
						['12!2009', 7.50],
						['1!2010', 7.50],
						['2!2010', 7.50],
						['3!2010', 7.50],
						['4!2010', 7.50],
						['5!2010', 7.50],
						['6!2010', 7.50],
						['7!2010', 7.50],
						['8!2010', 7.50],
						['9!2010', 7.50],
						['10!2010', 7.50],
						['11!2010', 7.50],
						['12!2010', 7.50],
						['1!2011', 7.50],
						['2!2011', 7.50],
						['3!2011', 7.50],
						['4!2011', 7.50],
						['5!2011', 7.50],
						['6!2011', 7.50],
						['7!2011', 7.50],
						['8!2011', 7.50],
						['9!2011', 7.50],
						['10!2011', 7.50],
						['11!2011', 7.50],
						['12!2011', 7.50],
						['1!2012', 7.50],
						['2!2012', 7.50],
						['3!2012', 7.50],
						['4!2012', 7.50],
						['5!2012', 7.50],
						['6!2012', 7.50],
						['7!2012', 7.50],
						['8!2012', 7.50],
						['9!2012', 7.50],
						['10!2012', 7.50],
						['11!2012', 7.50],
						['12!2012', 7.50],
						['1!2013', 7.50],
						['2!2013', 7.50],
						['3!2013', 7.50],
						['4!2013', 7.50],
						['5!2013', 7.50],
						['6!2013', 7.50],
						['7!2013', 7.50],
						['8!2013', 7.50],
						['9!2013', 7.50],
						['10!2013', 7.50],
						['11!2013', 7.50],
						['12!2013', 7.50],
						['1!2014', 7.50],
						['2!2014', 7.50],
						['3!2014', 7.00],
						['4!2014', 5.50],
						['5!2014', 5.50],
						['6!2014', 5.50],
						['7!2014', 5.50],
						['8!2014', 5.50],
						['9!2014', 5.50],
						['10!2014', 5.50],
						['11!2014', 5.50],
						['12!2014', 5.50],
						['1!2015', 4.00],
						['2!2015', 4.00],
						['3!2015', 4.00],
						['4!2015', 4.00],
						['5!2015', 4.00],
						['6!2015', 4.00],
						['7!2015', 4.00],
						['8!2015', 4.00],
						['9!2015', 4.00],
						['10!2015', 4.00],
						['11!2015', 4.00],
						['12!2015', 4.00],
						['1!2016', 4.00],
						['2!2016', 4.00],
						['3!2016', 4.00],
						['4!2016', 4.00],
						['5!2016', 4.00],
						['6!2016', 4.00],
						['7!2016', 4.00],
						['8!2016', 4.00],
						['9!2016', 4.50],
						['10!2016', 4.50],
						['11!2016', 4.50],
						['12!2016', 4.50],
						['1!2017', 4.50],
						['2!2017', 4.50],
						['3!2017', 5.00],
						['4!2017', 5.00],
						['5!2017', 5.00],
						['6!2017', 5.00],
						['7!2017', 5.00],
						['8!2017', 5.00],
						['9!2017', 5.00],
						['10!2017', 5.00],
						['11!2017', 5.00],
						['12!2017', 5.00],
						['1!2018', 5.00],
						['2!2018', 6.00]
					]
				},
				{
					name: localize({
						ru: `Рейтинг НН`,
						en: 'Nornickel'
					}),
					type: 'line',
					step: 'middle',
					showSymbol: false,
					data: [
						['8!2004', 3.00],
						['9!2004', 3.00],
						['10!2004', 3.00],
						['11!2004', 3.00],
						['12!2004', 3.00],
						['1!2005', 3.00],
						['2!2005', 3.00],
						['3!2005', 3.00],
						['4!2005', 3.00],
						['5!2005', 3.00],
						['6!2005', 3.00],
						['7!2005', 3.00],
						['8!2005', 4.50],
						['9!2005', 4.50],
						['10!2005', 4.50],
						['11!2005', 4.50],
						['12!2005', 4.50],
						['1!2006', 4.50],
						['2!2006', 4.50],
						['3!2006', 4.50],
						['4!2006', 4.50],
						['5!2006', 4.50],
						['6!2006', 4.50],
						['7!2006', 4.50],
						['8!2006', 6.00],
						['9!2006', 6.00],
						['10!2006', 6.00],
						['11!2006', 6.00],
						['12!2006', 6.00],
						['1!2007', 6.00],
						['2!2007', 6.00],
						['3!2007', 6.00],
						['4!2007', 6.00],
						['5!2007', 6.00],
						['6!2007', 6.00],
						['7!2007', 6.00],
						['8!2007', 6.00],
						['9!2007', 6.00],
						['10!2007', 6.00],
						['11!2007', 6.00],
						['12!2007', 6.00],
						['1!2008', 6.00],
						['2!2008', 6.00],
						['3!2008', 6.00],
						['4!2008', 6.00],
						['5!2008', 6.00],
						['6!2008', 6.00],
						['7!2008', 6.00],
						['8!2008', 6.00],
						['9!2008', 6.00],
						['10!2008', 6.00],
						['11!2008', 6.00],
						['12!2008', 6.00],
						['1!2009', 6.00],
						['2!2009', 5.50],
						['3!2009', 5.50],
						['4!2009', 5.50],
						['5!2009', 5.50],
						['6!2009', 5.50],
						['7!2009', 5.50],
						['8!2009', 5.50],
						['9!2009', 5.50],
						['10!2009', 5.50],
						['11!2009', 5.50],
						['12!2009', 5.50],
						['1!2010', 5.50],
						['2!2010', 5.50],
						['3!2010', 5.50],
						['4!2010', 5.50],
						['5!2010', 5.50],
						['6!2010', 5.50],
						['7!2010', 6.00],
						['8!2010', 6.00],
						['9!2010', 6.00],
						['10!2010', 6.00],
						['11!2010', 6.00],
						['12!2010', 5.50],
						['1!2011', 5.50],
						['2!2011', 5.50],
						['3!2011', 5.50],
						['4!2011', 5.50],
						['5!2011', 5.50],
						['6!2011', 5.50],
						['7!2011', 5.50],
						['8!2011', 5.50],
						['9!2011', 5.50],
						['10!2011', 5.50],
						['11!2011', 5.50],
						['12!2011', 5.50],
						['1!2012', 5.50],
						['2!2012', 5.50],
						['3!2012', 5.50],
						['4!2012', 5.50],
						['5!2012', 5.50],
						['6!2012', 5.50],
						['7!2012', 5.50],
						['8!2012', 5.50],
						['9!2012', 5.50],
						['10!2012', 5.50],
						['11!2012', 5.50],
						['12!2012', 5.50],
						['1!2013', 5.50],
						['2!2013', 5.50],
						['3!2013', 5.50],
						['4!2013', 5.50],
						['5!2013', 5.50],
						['6!2013', 5.50],
						['7!2013', 5.50],
						['8!2013', 5.50],
						['9!2013', 5.50],
						['10!2013', 6.00],
						['11!2013', 6.00],
						['12!2013', 6.00],
						['1!2014', 6.00],
						['2!2014', 6.00],
						['3!2014', 6.00],
						['4!2014', 5.50],
						['5!2014', 5.50],
						['6!2014', 5.50],
						['7!2014', 5.50],
						['8!2014', 5.50],
						['9!2014', 5.50],
						['10!2014', 5.50],
						['11!2014', 5.50],
						['12!2014', 5.50],
						['1!2015', 5.50],
						['2!2015', 5.50],
						['3!2015', 5.50],
						['4!2015', 5.50],
						['5!2015', 5.50],
						['6!2015', 5.50],
						['7!2015', 5.50],
						['8!2015', 5.50],
						['9!2015', 5.50],
						['10!2015', 5.50],
						['11!2015', 5.50],
						['12!2015', 5.50],
						['1!2016', 5.50],
						['2!2016', 5.50],
						['3!2016', 5.50],
						['4!2016', 5.50],
						['5!2016', 5.50],
						['6!2016', 5.50],
						['7!2016', 5.50],
						['8!2016', 5.50],
						['9!2016', 5.50],
						['10!2016', 5.50],
						['11!2016', 5.50],
						['12!2016', 5.50],
						['1!2017', 5.50],
						['2!2017', 5.50],
						['3!2017', 5.50],
						['4!2017', 5.50],
						['5!2017', 5.50],
						['6!2017', 5.50],
						['7!2017', 5.50],
						['8!2017', 5.50],
						['9!2017', 5.50],
						['10!2017', 6.00],
						['11!2017', 6.00],
						['12!2017', 6.00],
						['1!2018', 6.00],
						['2!2018', 6.00]
					]
				}
			],
			color: ['#cb2222', '#005a9d']
		});

	$(window).on('resize', function () {
		if (creditRating2Chart != null && creditRating2Chart != undefined) {
			creditRating2Chart.resize();
		}
	});
}

if (creditRating3) {
	let creditRating3Chart = echarts.init(creditRating3, chartsTheme);

	creditRating3Chart
		.setOption({
			title: {
				show: false
			},
			tooltip: {
				trigger: 'axis',
				formatter: function (value, index) {
					let newValue = `${ratingsLegendX[value[0].axisValue.substr(0, value[0].axisValue.indexOf('!'))]} ${value[0].axisValue.substr(value[0].axisValue.indexOf('!') + 1, value.length + 2)}`;
					value.forEach(function(el, i, chartsArr) {
						let val = ratingsLegendY[el.value[1]][1],
								valLast = val[val.length - 1],
								valStr = val.substr(0, val.length - 1),
								text;
						if (valLast == 'S') {
							text = localize({
								ru: 'стабильн',
								en: 'stable'
							})
						} else if (valLast == '+') {
							text = localize({
								ru: 'положит',
								en: 'positive'
							})
						} else if (valLast == '-') {
							if (el.value[1] == 5.5 && (el.seriesName == 'Рейтинг НН' || el.seriesName == 'Nornickel') && el.value[0].split('!')[1] == '2018') {
								text = 'RWN';
							} else {
								text = localize({
									ru: 'отрицат',
									en: 'negative'
								})
							}
						}
						valStr = valStr + text; 
						newValue += '<br><span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:' + el.color +';"></span>' + el.seriesName + ': ' + valStr;
					})
					return `${newValue}`;
				}
			},
			legend: {
				data: [
					localize({
						ru: `Рейтинг НН`,
						en: 'Nornickel'
					}),
					localize({
						ru: `Рейтинг РФ`,
						en: 'Russia'
					})
				]
			},
			grid: {
				left: '0',
				right: '2%',
				bottom: '20',
				containLabel: true
			},
			xAxis: {
				type: 'category',
				splitLine: {
					show: false
				},
				axisLabel: {
					formatter: function (value, index) {
						// return `${ratingsLegendX[value.substr(0, value.indexOf('!'))]} ${value.substr(value.indexOf('!') + 1, value.length)}`;
						return value.substr(value.indexOf('!') + 1, value.length);
					},
					interval: 11,
					rotate: 90
				},
				data: [
					'1!2004',
					'2!2004',
					'3!2004',
					'4!2004',
					'5!2004',
					'6!2004',
					'7!2004',
					'8!2004',
					'9!2004',
					'10!2004',
					'11!2004',
					'12!2004',
					'1!2005',
					'2!2005',
					'3!2005',
					'4!2005',
					'5!2005',
					'6!2005',
					'7!2005',
					'8!2005',
					'9!2005',
					'10!2005',
					'11!2005',
					'12!2005',
					'1!2006',
					'2!2006',
					'3!2006',
					'4!2006',
					'5!2006',
					'6!2006',
					'7!2006',
					'8!2006',
					'9!2006',
					'10!2006',
					'11!2006',
					'12!2006',
					'1!2007',
					'2!2007',
					'3!2007',
					'4!2007',
					'5!2007',
					'6!2007',
					'7!2007',
					'8!2007',
					'9!2007',
					'10!2007',
					'11!2007',
					'12!2007',
					'1!2008',
					'2!2008',
					'3!2008',
					'4!2008',
					'5!2008',
					'6!2008',
					'7!2008',
					'8!2008',
					'9!2008',
					'10!2008',
					'11!2008',
					'12!2008',
					'1!2009',
					'2!2009',
					'3!2009',
					'4!2009',
					'5!2009',
					'6!2009',
					'7!2009',
					'8!2009',
					'9!2009',
					'10!2009',
					'11!2009',
					'12!2009',
					'1!2010',
					'2!2010',
					'3!2010',
					'4!2010',
					'5!2010',
					'6!2010',
					'7!2010',
					'8!2010',
					'9!2010',
					'10!2010',
					'11!2010',
					'12!2010',
					'1!2011',
					'2!2011',
					'3!2011',
					'4!2011',
					'5!2011',
					'6!2011',
					'7!2011',
					'8!2011',
					'9!2011',
					'10!2011',
					'11!2011',
					'12!2011',
					'1!2012',
					'2!2012',
					'3!2012',
					'4!2012',
					'5!2012',
					'6!2012',
					'7!2012',
					'8!2012',
					'9!2012',
					'10!2012',
					'11!2012',
					'12!2012',
					'1!2013',
					'2!2013',
					'3!2013',
					'4!2013',
					'5!2013',
					'6!2013',
					'7!2013',
					'8!2013',
					'9!2013',
					'10!2013',
					'11!2013',
					'12!2013',
					'1!2014',
					'2!2014',
					'3!2014',
					'4!2014',
					'5!2014',
					'6!2014',
					'7!2014',
					'8!2014',
					'9!2014',
					'10!2014',
					'11!2014',
					'12!2014',
					'1!2015',
					'2!2015',
					'3!2015',
					'4!2015',
					'5!2015',
					'6!2015',
					'7!2015',
					'8!2015',
					'9!2015',
					'10!2015',
					'11!2015',
					'12!2015',
					'1!2016',
					'2!2016',
					'3!2016',
					'4!2016',
					'5!2016',
					'6!2016',
					'7!2016',
					'8!2016',
					'9!2016',
					'10!2016',
					'11!2016',
					'12!2016',
					'1!2017',
					'2!2017',
					'3!2017',
					'4!2017',
					'5!2017',
					'6!2017',
					'7!2017',
					'8!2017',
					'9!2017',
					'10!2017',
					'11!2017',
					'12!2017',
					'1!2018',
					'2!2018',
					'3!2018',
					'4!2018',
					'5!2018',
					'6!2018',
					'7!2018',
					'8!2018',
					'9!2018',
					'10!2018',
					'11!2018',
					'12!2018'
				]
			},
			yAxis: {
				type: 'value',
				data: [9.5, 9, 8.5, 8, 7.5, 7, 6.5, 6, 5.5, 5, 4.5, 4, 3.5, 3, 2.5],
				splitNumber: 14,
				min: 2.5,
				max: 9.5,
				axisLabel: {
					formatter: function (value, index) {
						let newVal = ratingsLegendY[value][1];
						if (newVal[newVal.length - 1] !== 'S') {
							newVal = newVal.substr(newVal.length - 1);
						}
						return newVal;
					},
					fontSize: 14
				}
			},
			series: [
				{
					name: localize({
						ru: `Рейтинг РФ`,
						en: 'Russia'
					}),
					type: 'line',
					step: 'middle',
					showSymbol: false,
					data: [
						['12!2005', 7.50],
						['1!2006', 7.50],
						['2!2006', 7.50],
						['3!2006', 7.50],
						['4!2006', 7.50],
						['5!2006', 7.50],
						['6!2006', 7.50],
						['7!2006', 9.00],
						['8!2006', 9.00],
						['9!2006', 9.00],
						['10!2006', 9.00],
						['11!2006', 9.00],
						['12!2006', 9.00],
						['1!2007', 9.00],
						['2!2007', 9.00],
						['3!2007', 9.00],
						['4!2007', 9.00],
						['5!2007', 9.00],
						['6!2007', 9.00],
						['7!2007', 9.00],
						['8!2007', 9.00],
						['9!2007', 9.00],
						['10!2007', 9.00],
						['11!2007', 9.00],
						['12!2007', 9.00],
						['1!2008', 9.00],
						['2!2008', 9.00],
						['3!2008', 9.00],
						['4!2008', 9.00],
						['5!2008', 9.00],
						['6!2008', 9.00],
						['7!2008', 9.00],
						['8!2008', 9.00],
						['9!2008', 9.00],
						['10!2008', 9.00],
						['11!2008', 8.50],
						['12!2008', 8.50],
						['1!2009', 8.50],
						['2!2009', 7.00],
						['3!2009', 7.00],
						['4!2009', 7.00],
						['5!2009', 7.00],
						['6!2009', 7.00],
						['7!2009', 7.00],
						['8!2009', 7.00],
						['9!2009', 7.00],
						['10!2009', 7.00],
						['11!2009', 7.00],
						['12!2009', 7.50],
						['1!2010', 7.50],
						['2!2010', 7.50],
						['3!2010', 7.50],
						['4!2010', 7.50],
						['5!2010', 7.50],
						['6!2010', 7.50],
						['7!2010', 7.50],
						['8!2010', 7.50],
						['9!2010', 8.00],
						['10!2010', 8.00],
						['11!2010', 8.00],
						['12!2010', 8.00],
						['1!2011', 8.00],
						['2!2011', 8.00],
						['3!2011', 8.00],
						['4!2011', 8.00],
						['5!2011', 8.00],
						['6!2011', 8.00],
						['7!2011', 8.00],
						['8!2011', 8.00],
						['9!2011', 8.00],
						['10!2011', 8.00],
						['11!2011', 8.00],
						['12!2011', 8.00],
						['1!2012', 7.50],
						['2!2012', 7.50],
						['3!2012', 7.50],
						['4!2012', 7.50],
						['5!2012', 7.50],
						['6!2012', 7.50],
						['7!2012', 7.50],
						['8!2012', 7.50],
						['9!2012', 7.50],
						['10!2012', 7.50],
						['11!2012', 7.50],
						['12!2012', 7.50],
						['1!2013', 7.50],
						['2!2013', 7.50],
						['3!2013', 7.50],
						['4!2013', 7.50],
						['5!2013', 7.50],
						['6!2013', 7.50],
						['7!2013', 7.50],
						['8!2013', 7.50],
						['9!2013', 7.50],
						['10!2013', 7.50],
						['11!2013', 7.50],
						['12!2013', 7.50],
						['1!2014', 7.50],
						['2!2014', 7.50],
						['3!2014', 7.00],
						['4!2014', 7.00],
						['5!2014', 7.00],
						['6!2014', 7.00],
						['7!2014', 7.00],
						['8!2014', 7.00],
						['9!2014', 7.00],
						['10!2014', 7.00],
						['11!2014', 7.00],
						['12!2014', 7.00],
						['1!2015', 5.50],
						['2!2015', 5.50],
						['3!2015', 5.50],
						['4!2015', 5.50],
						['5!2015', 5.50],
						['6!2015', 5.50],
						['7!2015', 5.50],
						['8!2015', 5.50],
						['9!2015', 5.50],
						['10!2015', 5.50],
						['11!2015', 5.50],
						['12!2015', 5.50],
						['1!2016', 5.50],
						['2!2016', 5.50],
						['3!2016', 5.50],
						['4!2016', 5.50],
						['5!2016', 5.50],
						['6!2016', 5.50],
						['7!2016', 5.50],
						['8!2016', 5.50],
						['9!2016', 5.50],
						['10!2016', 6.00],
						['11!2016', 6.00],
						['12!2016', 6.00],
						['1!2017', 6.00],
						['2!2017', 6.00],
						['3!2017', 6.00],
						['4!2017', 6.00],
						['5!2017', 6.00],
						['6!2017', 6.00],
						['7!2017', 6.00],
						['8!2017', 6.00],
						['9!2017', 6.50],
						['10!2017', 6.50],
						['11!2017', 6.50],
						['12!2017', 6.50],
						['1!2018', 6.50],
						['2!2018', 6.50],
						['3!2018', 6.50]
					]
				},
				{
					name: localize({
						ru: `Рейтинг НН`,
						en: 'Nornickel'
					}),
					type: 'line',
					step: 'middle',
					showSymbol: false,
					data: [
						['12!2005', 6.00],
						['1!2006', 6.00],
						['2!2006', 6.00],
						['3!2006', 6.00],
						['4!2006', 6.00],
						['5!2006', 6.00],
						['6!2006', 6.00],
						['7!2006', 6.00],
						['8!2006', 6.00],
						['9!2006', 6.00],
						['10!2006', 6.00],
						['11!2006', 6.00],
						['12!2006', 6.00],
						['1!2007', 6.00],
						['2!2007', 6.00],
						['3!2007', 6.00],
						['4!2007', 6.00],
						['5!2007', 6.00],
						['6!2007', 6.00],
						['7!2007', 6.00],
						['8!2007', 6.00],
						['9!2007', 6.00],
						['10!2007', 6.00],
						['11!2007', 6.00],
						['12!2007', 6.00],
						['1!2008', 6.00],
						['2!2008', 6.00],
						['3!2008', 6.00],
						['4!2008', 6.00],
						['5!2008', 6.00],
						['6!2008', 6.00],
						['7!2008', 6.00],
						['8!2008', 6.00],
						['9!2008', 6.00],
						['10!2008', 6.00],
						['11!2008', 6.00],
						['12!2008', 6.00],
						['1!2009', 6.00],
						['2!2009', 6.00],
						['3!2009', 5.50],
						['4!2009', 5.50],
						['5!2009', 5.50],
						['6!2009', 5.50],
						['7!2009', 5.50],
						['8!2009', 5.50],
						['9!2009', 5.50],
						['10!2009', 5.50],
						['11!2009', 6.00],
						['12!2009', 6.00],
						['1!2010', 6.00],
						['2!2010', 6.00],
						['3!2010', 6.00],
						['4!2010', 6.00],
						['5!2010', 6.00],
						['6!2010', 6.00],
						['7!2010', 6.00],
						['8!2010', 6.00],
						['9!2010', 6.00],
						['10!2010', 6.00],
						['11!2010', 6.00],
						['12!2010', 6.00],
						['1!2011', 6.00],
						['2!2011', 6.00],
						['3!2011', 6.00],
						['4!2011', 6.00],
						['5!2011', 6.00],
						['6!2011', 6.00],
						['7!2011', 6.00],
						['8!2011', 4.50],
						['9!2011', 4.50],
						['10!2011', 4.50],
						['11!2011', 4.50],
						['12!2011', 4.50],
						['1!2012', 4.50],
						['2!2012', 4.50],
						['3!2012', 4.50],
						['4!2012', 4.50],
						['5!2012', 4.50],
						['6!2012', 4.50],
						['7!2012', 4.50],
						['8!2012', 4.50],
						['9!2012', 4.50],
						['10!2012', 4.50],
						['11!2012', 4.50],
						['12!2012', 4.50],
						['1!2013', 4.50],
						['2!2013', 4.50],
						['3!2013', 4.50],
						['4!2013', 5.00],
						['5!2013', 5.00],
						['6!2013', 5.00],
						['7!2013', 5.00],
						['8!2013', 5.00],
						['9!2013', 5.00],
						['10!2013', 5.00],
						['11!2013', 5.00],
						['12!2013', 5.00],
						['1!2014', 5.00],
						['2!2014', 5.00],
						['3!2014', 5.00],
						['4!2014', 5.00],
						['5!2014', 5.00],
						['6!2014', 5.00],
						['7!2014', 6.00],
						['8!2014', 6.00],
						['9!2014', 6.00],
						['10!2014', 6.00],
						['11!2014', 6.00],
						['12!2014', 6.00],
						['1!2015', 5.50],
						['2!2015', 5.50],
						['3!2015', 5.50],
						['4!2015', 5.50],
						['5!2015', 5.50],
						['6!2015', 5.50],
						['7!2015', 5.50],
						['8!2015', 5.50],
						['9!2015', 5.50],
						['10!2015', 5.50],
						['11!2015', 5.50],
						['12!2015', 5.50],
						['1!2016', 5.50],
						['2!2016', 5.50],
						['3!2016', 5.50],
						['4!2016', 5.50],
						['5!2016', 5.50],
						['6!2016', 5.50],
						['7!2016', 5.50],
						['8!2016', 5.50],
						['9!2016', 5.50],
						['10!2016', 6.00],
						['11!2016', 6.00],
						['12!2016', 6.00],
						['1!2017', 6.00],
						['2!2017', 6.00],
						['3!2017', 6.00],
						['4!2017', 6.00],
						['5!2017', 6.00],
						['6!2017', 6.00],
						['7!2017', 6.00],
						['8!2017', 6.00],
						['9!2017', 6.00],
						['10!2017', 6.00],
						['11!2017', 6.00],
						['12!2017', 6.00],
						['1!2018', 6.00],
						['2!2018', 6.00],
						['3!2018', 5.50]
					]
				}
			],
			color: ['#cb2222', '#005a9d']
		});

	$(window).on('resize', function () {
		if (creditRating3Chart != null && creditRating3Chart != undefined) {
			creditRating3Chart.resize();
		}
	});
}

if (grCapital1) {
	let grCapital1Chart = echarts.init(grCapital1, chartsTheme);

	grCapital1Chart
		.setOption({
			tooltip: {
				trigger: 'item',
				formatter: '{b}'
			},
			legend: {
				// orient: 'vertical',
				formatter: function (name) {
					if (name == 'Olderfrey Holding Ltd') {
						return name + '(1)';
					} else if (name == 'UC Rusal Plc') {
						return name + '(2)';
					} else {
						return name;
					}
				},
				selectedMode: false,
				x: 'center',
				y: 'bottom',
				data: ['Olderfrey Holding Ltd', 'UC Rusal Plc', 'Crispian Investments Ltd', localize({
						ru: 'Прочие акционеры',
						en: 'Other shareholders'
					})]
			},
			calculable: true,
			series: [
				{
					name: 'Структура акционерного капитала',
					type: 'pie',
					center: ['50%', '40%'],
					clockWise: true,
					avoidLabelOverlap: false,
					radius: ['50%', '70%'],
					itemStyle: {
						normal: {
							label: {
								show: false,
								position: 'center',
							},
							labelLine: {
								show: false
							}
						},
						emphasis: {
							label: {
								show: true,
								formatter: function (params, ticket, callback) {
									let newValue = params.value.toString().replace(/\./g, ',');
									return `${newValue}%`;
								},
								textStyle: {
									fontSize: '30',
									fontWeight: 'bold'
								}
							}
						}
					},
					data: [
						{value: 34.15, name: 'Olderfrey Holding Ltd'},
						{value: 27.82, name: 'UC Rusal Plc'},
						{value: 38.03, name: localize({
							ru: 'Прочие акционеры',
							en: 'Other shareholders'
						})}
					]
				}
			]
		});

	$(window).on('resize', function () {
		if (grCapital1Chart != null && grCapital1Chart != undefined) {
			grCapital1Chart.resize();
		}
	});
}

if (grCapital2) {
	let grCapital2Chart = echarts.init(grCapital2, chartsTheme);

	grCapital2Chart
		.setOption({
			title: {
				show: true,
				text: 'На 31.12.2016'
			},
			tooltip: {
				show: true,
				formatter: '{b}'
			},
			legend: {
				// orient: 'vertical',
				selectedMode: false,
				x: 'center',
				y: 'bottom',
				data: [
					localize({
						ru: 'Акции',
						en: 'Shares'
					}),
					localize({
						ru: 'АДР',
						en: 'ADRs'
					})
				]
			},
			calculable: true,
			series: [
				{
					name: 'Распределение акций и АДР',
					type: 'pie',
					center: ['50%', '40%'],
					clockWise: true,
					avoidLabelOverlap: false,
					radius: ['50%', '70%'],
					itemStyle: {
						normal: {
							label: {
								show: false,
								position: 'center',
							},
							labelLine: {
								show: false
							}
						},
						emphasis: {
							label: {
								show: true,
								formatter: function (params, ticket, callback) {
									let newValue = params.value.toString().replace(/\./g, ',');
									return `${newValue}%`;
								},
								textStyle: {
									fontSize: '30',
									fontWeight: 'bold'
								}
							}
						}
					},
					data: [
						{value: 59.08, name: localize({
							ru: 'Акции',
							en: 'Shares'
						})},
						{value: 40.92, name: localize({
							ru: 'АДР',
							en: 'ADRs'
						})}
					]
				}
			],
			color: ['#0080C8', '#EBD3A1']
		});

	$(window).on('resize', function () {
		if (grCapital2Chart != null && grCapital2Chart != undefined) {
			grCapital2Chart.resize();
		}
	});
}

if (grLiquidity1) {
	let grLiquidity1Chart = echarts.init(grLiquidity1, chartsTheme);

	grLiquidity1Chart
		.setOption({
			tooltip: {
				show: true,
				formatter: '{a} - {c}'
			},
			legend: {
				// orient: 'vertical',
				selectedMode: false,
				x: 'center',
				y: 'bottom',
				data: [
					localize({
						ru: 'Краткосрочный долг',
						en: 'Short-term borrowings'
					}),
					localize({
						ru: 'Долгосрочный долг',
						en: 'Long-term borrowings'
					})]
			},
			xAxis: [
				{
					type: 'category',
					data: ['2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', localize({
						ru: '1П 2017',
						en: '6M 2017'
					})]
				}
			],
			yAxis: [
				{
					type: 'value'
				}
			],
			calculable: true,
			series: [
				{
					name: localize({
						ru: 'Долгосрочный долг',
						en: 'Long-term borrowings'
					}),
					type: 'bar',
					stack: 'stack',
					data: [2345, 1561, 2401, 2497, 5173, 5678, 7142, 7274, 6903]
				},
				{
					name: localize({
						ru: 'Краткосрочный долг',
						en: 'Short-term borrowings'
					}),
					type: 'bar',
					barWidth: '30%',
					stack: 'stack',
					data: [2972, 1236, 2754, 2526, 1032, 652, 1124, 578, 1325]
				}
			],
			color: ['#0080C8', '#DEB65E']
		});

	$(window).on('resize', function () {
		if (grLiquidity1Chart != null && grLiquidity1Chart != undefined) {
			grLiquidity1Chart.resize();
		}
	});
}

if (grLiquidity2) {
	let grLiquidity2Chart = echarts.init(grLiquidity2, chartsTheme);

	grLiquidity2Chart
		.setOption({
			tooltip: {
				show: true,
				formatter: function (params, ticket, callback) {
					let newValue = params.value.toString().replace(/\./g, ',');
					return `${params.seriesName} -  ${newValue}`;
				}
			},
			legend: {
				// orient: 'vertical',
				selectedMode: false,
				x: 'center',
				y: 'bottom',
				data: [localize({
						ru: 'Чистый Долг/EBITDA',
						en: 'Net Liabilities/EBITDA'
					})]
			},
			xAxis: [
				{
					type: 'category',
					data: ['2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', localize({
						ru: '1П 2017',
						en: '6M 2017'
					})]
				}
			],
			yAxis: [
				{
					type: 'value',
					axisLabel: {
						formatter: function (value, index) {
							let newValue = value.toString().replace(/\./g, ',');
							return newValue;
						}
					}
				}
			],
			calculable: true,
			series: [
				{
					name: localize({
						ru: 'Чистый Долг/EBITDA',
						en: 'Net Liabilities/EBITDA'
					}),
					type: 'line',
					data: [0.4, -0.4, 0.5, 0.8, 1.1, 0.6, 1.0, 1.2, 1.5]
				}
			],
			color: ['#00589c']
		});

	$(window).on('resize', function () {
		if (grLiquidity2Chart != null && grLiquidity2Chart != undefined) {
			grLiquidity2Chart.resize();
		}
	});
}

if (grLiquidity3) {
	let grLiquidity3Chart = echarts.init(grLiquidity3, chartsTheme);
	let liqPos = localize({
			ru: 'Ликвидная\n позиция',
			en: 'Liquidity\n position'
		});

	grLiquidity3Chart
		.setOption({
			tooltip: {
				show: true,
				formatter: function (params, ticket, callback) {
					let newValue = params.value.toString().replace(/\./g, ',');
					return `${params.seriesName} -  ${newValue}`;
				}
			},
			grid: {
				bottom: '120'
			},
			legend: {
				orient: 'vertical',
				selectedMode: false,
				x: 'center',
				y: 'bottom',
				bottom: 0,
				data: [
					localize({
						ru: 'График погашения кредитного портфеля компании, млрд долл. США',
						en: 'Debt repayments, USD bn'
					}),
					localize({
						ru: 'Денежные средства и их эквиваленты, млрд долл. США',
						en: 'Cash, USD bn'
					}),
					localize({
						ru: 'Доступные кредитные линии, млрд долл. США',
						en: 'Available Credit Lines, USD bn'
					})
				]
			},
			xAxis: [
				{
					type: 'category',
					data: [liqPos, '2017', '2018', '2019', '2020', '2021+']
				}
			],
			yAxis: [
				{
					type: 'value',
					axisLabel: {
						formatter: function (value, index) {
							let newValue = value.toString().replace(/\./g, ',');
							return newValue;
						}
					}
				}
			],
			calculable: true,
			series: [
				{
					name: localize({
						ru: 'График погашения кредитного портфеля компании, млрд долл. США',
						en: 'Debt repayments, USD bn'
					}),
					type: 'bar',
					stack: 'stack1',
					barWidth: '40%',
					data: [0, 1.3, 0.5, 0.2, 2.1, 4.1],
					itemStyle: {
						normal: {
							color: '#00589c'
						}
					}
				},
				{
					name: localize({
						ru: 'Денежные средства и их эквиваленты, млрд долл. США',
						en: 'Cash, USD bn'
					}),
					type: 'bar',
					stack: 'stack1',
					barWidth: '40%',
					data: [2.6],
					itemStyle: {
						normal: {
							color: '#0080C8'
						}
					}
				},
				{
					name: localize({
						ru: 'Доступные кредитные линии, млрд долл. США',
						en: 'Available Credit Lines, USD bn'
					}),
					type: 'bar',
					stack: 'stack1',
					barWidth: '40%',
					data: [2.2],
					itemStyle: {
						normal: {
							color: '#DEB65E'
						}
					}
				}
			]
		});

	$(window).on('resize', function () {
		if (grLiquidity3Chart != null && grLiquidity3Chart != undefined) {
			grLiquidity3Chart.resize();
		}
	});
}

if (grLiquidity4) {
	let grLiquidity4Chart = echarts.init(grLiquidity4, chartsTheme);

	grLiquidity4Chart
		.setOption({
			tooltip: {
				show: true,
				formatter: '{b} - {c}%'
			},
			legend: {
				// orient: 'vertical',
				selectedMode: false,
				x: 'center',
				y: 'bottom',
				data: ['RUB', 'USD']
			},
			calculable: true,
			series: [
				{
					name: localize({
						ru: 'Структура долга компании по&nbsp;валюте',
						en: 'Debt structure by currency'
					}),
					type: 'pie',
					clockWise: true,
					avoidLabelOverlap: false,
					radius: ['0%', '70%'],
					startAngle: 45,
					itemStyle: {
						normal: {
							label: {
								show: true,
								formatter: '{d}%',
								textStyle: {
									fontSize: '22',
									fontWeight: 'bold'
								}
							},
							labelLine: {
								show: true
							}
						}
					},
					data: [
						{value: 85, name: 'USD'},
						{value: 15, name: 'RUB'}
					]
				}
			],
			color: ['#0080C8', '#DEB65E']
		});

	$(window).on('resize', function () {
		if (grLiquidity4Chart != null && grLiquidity4Chart != undefined) {
			grLiquidity4Chart.resize();
		}
	});
}

if (grLiquidity5) {
	let grLiquidity5Chart = echarts.init(grLiquidity5, chartsTheme);

	grLiquidity5Chart
		.setOption({
			tooltip: {
				show: true,
				formatter: '{b} - {c}%'
			},
			legend: {
				// orient: 'vertical',
				selectedMode: false,
				x: 'center',
				y: 'bottom',
				data: [
					localize({
						ru: 'Фиксированная ставка',
						en: 'Fixed Rate'
					}),
					localize({
						ru: 'Плавающая ставка',
						en: 'Adjustable Rate'
					})
				]
			},
			calculable: true,
			series: [
				{
					name: localize({
						ru: 'Долг банковский/публичный',
						en: 'Debt structure by type'
					}),
					type: 'pie',
					clockWise: true,
					avoidLabelOverlap: false,
					radius: ['0%', '70%'],
					startAngle: 45,
					itemStyle: {
						normal: {
							label: {
								show: true,
								formatter: '{d}%',
								textStyle: {
									fontSize: '22',
									fontWeight: 'bold'
								}
							},
							labelLine: {
								show: true
							}
						}
					},
					// 	emphasis: {
					// 		label: {
					// 			show: true,
					// 			formatter: '{d}%',
					// 			textStyle: {
					// 				fontSize: '30',
					// 				fontWeight: 'bold'
					// 			}
					// 		}
					// 	}
					// },
					data: [
						{value: 67, name: localize({
						ru: 'Фиксированная ставка',
						en: 'Fixed Rate'
					})},
						{value: 33, name: localize({
						ru: 'Плавающая ставка',
						en: 'Adjustable Rate'
					})}
					]
				}
			],
			color: ['#0080C8', '#DEB65E']
		});

	$(window).on('resize', function () {
		if (grLiquidity5Chart != null && grLiquidity5Chart != undefined) {
			grLiquidity5Chart.resize();
		}
	});
}

if (bm1) {
	let bm1Chart = echarts.init(bm1, chartsTheme);

	bm1Chart
		.setOption({
			tooltip: {
				show: true,
				trigger: 'item',
				formatter: '{b}'
			},
			legend: {
				show: true,
				selectedMode: false,
				orient: 'vertical',
				x: 'right',
				y: 'top',
				data: ['Оцененные и выявленные запасы', 'Доказанные и вероятные запасы']
			},
			calculable: true,
			series: [
				{
					name: 'Оцененные и выявленные запасы',
					type: 'pie',
					hoverAnimation: false,
					avoidLabelOverlap: false,
					center: ['50%', '60%'],
					// silent: true,
					radius: ['0', '70%'],
					label: {
						normal: {
							show: true,
							formatter: '{c}',
							position: 'inner',
							textStyle: {
								fontSize: '18',
								fontWeight: '600',
								color: '#fff'
							}
						}
					},
					labelLine: {
						normal: {
							show: true,
							lineStyle: {
								color: '#333',
								width: 2
							}
						}
					},
					itemStyle: {
						normal: {
							color: '#626262',
						}
					},
					data: [
						{value: 2059, name: 'Оцененные и выявленные запасы'}
					]
				},
				{
					name: 'Доказанные и вероятные запасы',
					type: 'pie',
					zlevel: 1,
					hoverAnimation: false,
					avoidLabelOverlap: false,
					center: ['55%', '52%'],
					// silent: true,
					radius: ['0', '40%'],
					label: {
						normal: {
							show: true,
							formatter: '{c}',
							position: 'center',
							textStyle: {
								fontSize: '18',
								fontWeight: '600',
								color: '#fff'
							}
						}
					},
					labelLine: {
						normal: {
							show: true,
							lineStyle: {
								color: '#333',
								width: 2
							}
						}
					},
					itemStyle: {
						normal: {
							color: '#1281c6',
						}
					},
					data: [
						{value: 828, name: 'Доказанные и вероятные запасы'}
					]
				}
			]
		});

	$(window).on('resize', function () {
		if (bm1Chart != null && bm1Chart != undefined) {
			bm1Chart.resize();
		}
	});
}

if (bm2) {
	let bm2Chart = echarts.init(bm2, chartsTheme);

	bm2Chart
		.setOption({
			tooltip: {
				show: true,
				trigger: 'item',
				formatter: '{b}'
			},
			legend: {
				show: true,
				selectedMode: false,
				orient: 'vertical',
				x: 'right',
				y: 'top',
				data: ['Оцененные и выявленные запасы', 'Доказанные и вероятные запасы']
			},
			calculable: true,
			series: [
				{
					name: 'Оцененные и выявленные запасы',
					type: 'pie',
					hoverAnimation: false,
					avoidLabelOverlap: false,
					center: ['50%', '60%'],
					// silent: true,
					radius: ['0', '70%'],
					label: {
						normal: {
							show: true,
							formatter: '{c}',
							position: 'inner',
							textStyle: {
								fontSize: '18',
								fontWeight: '600',
								color: '#fff'
							}
						}
					},
					labelLine: {
						normal: {
							show: true,
							lineStyle: {
								color: '#333',
								width: 2
							}
						}
					},
					itemStyle: {
						normal: {
							color: '#626262',
						}
					},
					data: [
						{value: '14,3', name: 'Оцененные и выявленные запасы'}
					]
				},
				{
					name: 'Доказанные и вероятные запасы',
					type: 'pie',
					zlevel: 1,
					hoverAnimation: false,
					avoidLabelOverlap: false,
					center: ['55%', '52%'],
					// silent: true,
					radius: ['0', '40%'],
					label: {
						normal: {
							show: true,
							formatter: '{c}',
							position: 'center',
							textStyle: {
								fontSize: '18',
								fontWeight: '600',
								color: '#fff'
							}
						}
					},
					labelLine: {
						normal: {
							show: true,
							lineStyle: {
								color: '#333',
								width: 2
							}
						}
					},
					itemStyle: {
						normal: {
							color: '#1281c6',
						}
					},
					data: [
						{value: '7,1', name: 'Доказанные и вероятные запасы'}
					]
				}
			]
		});

	$(window).on('resize', function () {
		if (bm2Chart != null && bm2Chart != undefined) {
			bm2Chart.resize();
		}
	});
}

if (bm3) {
	let bm3Chart = echarts.init(bm3, chartsTheme);

	bm3Chart
		.setOption({
			tooltip: {
				show: true,
				trigger: 'item',
				formatter: '{b}'
			},
			legend: {
				show: true,
				selectedMode: false,
				orient: 'vertical',
				x: 'right',
				y: 'top',
				data: ['Оцененные и выявленные запасы', 'Доказанные и вероятные запасы']
			},
			calculable: true,
			series: [
				{
					name: 'Оцененные и выявленные запасы',
					type: 'pie',
					hoverAnimation: false,
					avoidLabelOverlap: false,
					center: ['50%', '60%'],
					// silent: true,
					radius: ['0', '70%'],
					label: {
						normal: {
							show: true,
							formatter: '{c}',
							position: 'inner',
							textStyle: {
								fontSize: '18',
								fontWeight: '600',
								color: '#fff'
							}
						}
					},
					labelLine: {
						normal: {
							show: true,
							lineStyle: {
								color: '#333',
								width: 2
							}
						}
					},
					itemStyle: {
						normal: {
							color: '#626262',
						}
					},
					data: [
						{value: '23,5', name: 'Оцененные и выявленные запасы'}
					]
				},
				{
					name: 'Доказанные и вероятные запасы',
					type: 'pie',
					zlevel: 1,
					hoverAnimation: false,
					avoidLabelOverlap: false,
					center: ['55%', '52%'],
					// silent: true,
					radius: ['0', '40%'],
					label: {
						normal: {
							show: true,
							formatter: '{c}',
							position: 'center',
							textStyle: {
								fontSize: '18',
								fontWeight: '600',
								color: '#fff'
							}
						}
					},
					labelLine: {
						normal: {
							show: true,
							lineStyle: {
								color: '#333',
								width: 2
							}
						}
					},
					itemStyle: {
						normal: {
							color: '#1281c6',
						}
					},
					data: [
						{value: '12,1', name: 'Доказанные и вероятные запасы'}
					]
				}
			]
		});

	$(window).on('resize', function () {
		if (bm3Chart != null && bm3Chart != undefined) {
			bm3Chart.resize();
		}
	});
}

if (bm4) {
	let bm4Chart = echarts.init(bm4, chartsTheme);

	bm4Chart
		.setOption({
			tooltip: {
				show: true,
				trigger: 'item',
				formatter: '{b}'
			},
			legend: {
				show: true,
				selectedMode: false,
				orient: 'vertical',
				x: 'right',
				y: 'top',
				data: ['Оцененные и выявленные запасы', 'Доказанные и вероятные запасы']
			},
			calculable: true,
			series: [
				{
					name: 'Оцененные и выявленные запасы',
					type: 'pie',
					hoverAnimation: false,
					avoidLabelOverlap: false,
					center: ['50%', '60%'],
					// silent: true,
					radius: ['0', '70%'],
					label: {
						normal: {
							show: true,
							formatter: '{c}',
							position: 'inner',
							textStyle: {
								fontSize: '18',
								fontWeight: '600',
								color: '#fff'
							}
						}
					},
					labelLine: {
						normal: {
							show: true,
							lineStyle: {
								color: '#333',
								width: 2
							}
						}
					},
					itemStyle: {
						normal: {
							color: '#626262',
						}
					},
					data: [
						{value: '196,5', name: 'Оцененные и выявленные запасы'}
					]
				},
				{
					name: 'Доказанные и вероятные запасы',
					type: 'pie',
					zlevel: 1,
					hoverAnimation: false,
					avoidLabelOverlap: false,
					center: ['55%', '52%'],
					// silent: true,
					radius: ['0', '40%'],
					label: {
						normal: {
							show: true,
							formatter: '{c}',
							position: 'center',
							textStyle: {
								fontSize: '18',
								fontWeight: '600',
								color: '#fff'
							}
						}
					},
					labelLine: {
						normal: {
							show: true,
							lineStyle: {
								color: '#333',
								width: 2
							}
						}
					},
					itemStyle: {
						normal: {
							color: '#1281c6',
						}
					},
					data: [
						{value: '93,5', name: 'Доказанные и вероятные запасы'}
					]
				}
			]
		});

	$(window).on('resize', function () {
		if (bm4Chart != null && bm4Chart != undefined) {
			bm4Chart.resize();
		}
	});
}

if (bm5) {
	let bm5Chart = echarts.init(bm5, chartsTheme);

	bm5Chart
		.setOption({
			tooltip: {
				show: true,
				trigger: 'item',
				formatter: '{b}'
			},
			legend: {
				show: true,
				selectedMode: false,
				orient: 'vertical',
				x: 'right',
				y: 'top',
				data: ['Оцененные и выявленные запасы', 'Доказанные и вероятные запасы']
			},
			calculable: true,
			series: [
				{
					name: 'Оцененные и выявленные запасы',
					type: 'pie',
					hoverAnimation: false,
					avoidLabelOverlap: false,
					center: ['50%', '60%'],
					// silent: true,
					radius: ['0', '70%'],
					label: {
						normal: {
							show: true,
							formatter: '{c}',
							position: 'inner',
							textStyle: {
								fontSize: '18',
								fontWeight: '600',
								color: '#fff'
							}
						}
					},
					labelLine: {
						normal: {
							show: true,
							lineStyle: {
								color: '#333',
								width: 2
							}
						}
					},
					itemStyle: {
						normal: {
							color: '#626262',
						}
					},
					data: [
						{value: '55,8', name: 'Оцененные и выявленные запасы'}
					]
				}
				// {
				// 	name: 'Доказанные и вероятные запасы',
				// 	type: 'pie',
				// 	zlevel: 1,
				// 	hoverAnimation: false,
				// 	avoidLabelOverlap: false,
				// 	center: ['55%', '52%'],
				// 	// silent: true,
				// 	radius: ['0', '40%'],
				// 	label: {
				// 		normal: {
				// 			show: true,
				// 			formatter: '{c}',
				// 			position: 'center',
				// 			textStyle: {
				// 				fontSize: '18',
				// 				fontWeight: '600',
				// 				color: '#fff'
				// 			}
				// 		}
				// 	},
				// 	labelLine: {
				// 		normal: {
				// 			show: true,
				// 			lineStyle: {
				// 				color: '#333',
				// 				width: 2
				// 			}
				// 		}
				// 	},
				// 	data: [
				// 		{value: 93.5, name: 'Доказанные и вероятные запасы'}
				// 	]
				// }
			]
		});

	$(window).on('resize', function () {
		if (bm5Chart != null && bm5Chart != undefined) {
			bm5Chart.resize();
		}
	});
}

function initChartStrategy1() {
	let grStrategy1Chart = echarts.init(grStrategy1, chartsTheme);

	grStrategy1Chart
		.setOption({
			grid: [
					{x: '10%', y: '0', width: '90%', height: '100%'}
			],
			xAxis: {
				show: false,
			},
			yAxis: {
				show: false,
				type: 'category'
			},
			series: [{
				type: 'bar',
				stack: 1,
				itemStyle: {
					normal: {
						color: '#00579C'
					}
				},
				label: {
					normal: {
						show: true,
						position: 'insideLeft',
						offset: [10, -2],
						formatter: function (value, index) {
							let newValue = value.data.toString().replace(/\./g, ',');
							return newValue;
						},
						textStyle: {
							color: '#fff',
							fontSize: 15
						}
					}
				},
				z: 3,
				data: [0.43]
			}, {
				type: 'bar',
				stack: 1,
				name: '2017',
				itemStyle: {
					normal: {
						color: '#F2F2F6'
					}
				},
				silent: true,
				label: {
					normal: {
						show: true,
						position: 'insideRight',
						formatter: '{a}',
						offset: [-10, -2],
						textStyle: {
							color: '#626262',
							fontSize: 15
						}
					}
				},
				data: [0.57]
			},
			{
				type: 'bar',
				stack: 2,
				itemStyle: {
					normal: {
						color: '#0080CA'
					}
				},
				label: {
					normal: {
						show: true,
						position: 'insideLeft',
						offset: [10, -2],
						formatter: function (value, index) {
							let newValue = value.data.toString().replace(/\./g, ',');
							return newValue;
						},
						textStyle: {
							color: '#fff',
							fontSize: 15
						}
					}
				},
				z: 3,
				data: [0.35]
			}, {
				type: 'bar',
				stack: 2,
				name: '2016',
				itemStyle: {
					normal: {
						color: '#F2F2F6'
					}
				},
				silent: true,
				label: {
					normal: {
						show: true,
						position: 'insideRight',
						formatter: '{a}',
						offset: [-10, -2],
						textStyle: {
							color: '#626262',
							fontSize: 15
						}
					}
				},
				data: [0.65]
			},
			{
				type: 'bar',
				stack: 3,
				itemStyle: {
					normal: {
						color: '#0080CA'
					}
				},
				label: {
					normal: {
						show: true,
						position: 'insideLeft',
						offset: [10, -2],
						formatter: function (value, index) {
							let newValue = value.data.toString().replace(/\./g, ',');
							return newValue;
						},
						textStyle: {
							color: '#fff',
							fontSize: 15
						}
					}
				},
				z: 3,
				data: [0.62]
			}, {
				type: 'bar',
				stack: 3,
				name: '2015',
				itemStyle: {
					normal: {
						color: '#F2F2F6'
					}
				},
				silent: true,
				label: {
					normal: {
						show: true,
						position: 'insideRight',
						formatter: '{a}',
						offset: [-10, -2],
						textStyle: {
							color: '#626262',
							fontSize: 15
						}
					}
				},
				data: [0.38]
			},
			{
				type: 'bar',
				stack: 4,
				itemStyle: {
					normal: {
						color: '#0080CA'
					}
				},
				label: {
					normal: {
						show: true,
						position: 'insideLeft',
						offset: [10, -2],
						formatter: function (value, index) {
							let newValue = value.data.toString().replace(/\./g, ',');
							return newValue;
						},
						textStyle: {
							color: '#fff',
							fontSize: 15
						}
					}
				},
				z: 3,
				data: [0.48]
			}, {
				type: 'bar',
				stack: 4,
				name: '2014',
				itemStyle: {
					normal: {
						color: '#F2F2F6'
					}
				},
				silent: true,
				label: {
					normal: {
						show: true,
						position: 'insideRight',
						formatter: '{a}',
						offset: [-10, -2],
						textStyle: {
							color: '#626262',
							fontSize: 15
						}
					}
				},
				data: [0.52]
			},
			{
				type: 'bar',
				stack: 5,
				itemStyle: {
					normal: {
						color: '#0080CA'
					}
				},
				label: {
					normal: {
						show: true,
						position: 'insideLeft',
						offset: [10, -2],
						formatter: function (value, index) {
							let newValue = value.data.toString().replace(/\./g, ',');
							return newValue;
						},
						textStyle: {
							color: '#fff',
							fontSize: 15
						}
					}
				},
				z: 3,
				data: [0.8]
			}, {
				type: 'bar',
				stack: 5,
				name: '2013',
				itemStyle: {
					normal: {
						color: '#F2F2F6'
					}
				},
				silent: true,
				label: {
					normal: {
						show: true,
						position: 'insideRight',
						formatter: '{a}',
						offset: [-10, -2],
						textStyle: {
							color: '#626262',
							fontSize: 15
						}
					}
				},
				data: [0.2]
			}],
			animationDuration: 1400,
			animationEasing: 'cubicOut'
		});

	$('#strategy-chart-1').addClass('is-loaded');

	$(window).on('resize', function () {
		if (grStrategy1Chart != null && grStrategy1Chart != undefined) {
			grStrategy1Chart.resize();
		}
	});
}

function initChartStrategy2() {
	let grStrategy2Chart = echarts.init(grStrategy2, chartsTheme);

	grStrategy2Chart
		.setOption({
			grid: [
					{x: '10%', y: '0', width: '90%', height: '100%'}
			],
			xAxis: {
				show: false,
			},
			yAxis: {
				show: false,
				type: 'category'
			},
			series: [{
				type: 'bar',
				stack: 1,
				itemStyle: {
					normal: {
						color: '#00579C'
					}
				},
				label: {
					normal: {
						show: true,
						position: 'insideLeft',
						offset: [10, -2],
						formatter: function (value, index) {
							let newValue = value.data.toString().replace(/\./g, ',');
							return newValue;
						},
						textStyle: {
							color: '#fff',
							fontSize: 16
						}
					}
				},
				z: 3,
				data: [2.6]
			}, {
				type: 'bar',
				stack: 1,
				name: '2017',
				itemStyle: {
					normal: {
						color: '#F2F2F6'
					}
				},
				silent: true,
				label: {
					normal: {
						show: true,
						position: 'insideRight',
						formatter: '{a}',
						offset: [-10, -2],
						textStyle: {
							color: '#626262',
							fontSize: 16
						}
					}
				},
				data: [1.4]
			},
			{
				type: 'bar',
				stack: 2,
				itemStyle: {
					normal: {
						color: '#0080CA'
					}
				},
				label: {
					normal: {
						show: true,
						position: 'insideLeft',
						offset: [10, -2],
						formatter: function (value, index) {
							let newValue = value.data.toString().replace(/\./g, ',');
							return newValue;
						},
						textStyle: {
							color: '#fff',
							fontSize: 16
						}
					}
				},
				z: 3,
				data: [2.5]
			}, {
				type: 'bar',
				stack: 2,
				name: '2016',
				itemStyle: {
					normal: {
						color: '#F2F2F6'
					}
				},
				silent: true,
				label: {
					normal: {
						show: true,
						position: 'insideRight',
						formatter: '{a}',
						offset: [-10, -2],
						textStyle: {
							color: '#626262',
							fontSize: 16
						}
					}
				},
				data: [1.5]
			},
			{
				type: 'bar',
				stack: 3,
				itemStyle: {
					normal: {
						color: '#0080CA'
					}
				},
				label: {
					normal: {
						show: true,
						position: 'insideLeft',
						offset: [10, -2],
						formatter: function (value, index) {
							let newValue = value.data.toString().replace(/\./g, ',');
							return newValue;
						},
						textStyle: {
							color: '#fff',
							fontSize: 16
						}
					}
				},
				z: 3,
				data: [2.3]
			}, {
				type: 'bar',
				stack: 3,
				name: '2015',
				itemStyle: {
					normal: {
						color: '#F2F2F6'
					}
				},
				silent: true,
				label: {
					normal: {
						show: true,
						position: 'insideRight',
						formatter: '{a}',
						offset: [-10, -2],
						textStyle: {
							color: '#626262',
							fontSize: 16
						}
					}
				},
				data: [1.7]
			},
			{
				type: 'bar',
				stack: 4,
				itemStyle: {
					normal: {
						color: '#0080CA'
					}
				},
				label: {
					normal: {
						show: true,
						position: 'insideLeft',
						offset: [10, -2],
						formatter: function (value, index) {
							let newValue;
							if (decimalLength(value.data) === 0) {
								newValue = value.data.toString() + '.0';
								newValue = newValue.replace(/\./g, ',');
							} else {
								newValue = value.data.toString().replace(/\./g, ',');
							}
							return newValue;
						},
						textStyle: {
							color: '#fff',
							fontSize: 16
						}
					}
				},
				z: 3,
				data: [2.0]
			}, {
				type: 'bar',
				stack: 4,
				name: '2014',
				itemStyle: {
					normal: {
						color: '#F2F2F6'
					}
				},
				silent: true,
				label: {
					normal: {
						show: true,
						position: 'insideRight',
						formatter: '{a}',
						offset: [-10, -2],
						textStyle: {
							color: '#626262',
							fontSize: 16
						}
					}
				},
				data: [2.0]
			}],
			animationDuration: 1400,
			animationEasing: 'cubicOut'
		});

	$('#strategy-chart-2').addClass('is-loaded');

	$(window).on('resize', function () {
		if (grStrategy2Chart != null && grStrategy2Chart != undefined) {
			grStrategy2Chart.resize();
		}
	});
}

// INIT
if (grStrategy1 && !$('#strategy-chart-1').hasClass('is-loaded')) {
	if (verge.inViewport($('#strategy-chart-1'), '10%')) {
		initChartStrategy1();
	}
}

if (grStrategy2 && !$('#strategy-chart-2').hasClass('is-loaded')) {
	if (verge.inViewport($('#strategy-chart-2'), '10%')) {
		initChartStrategy2();
	}
}

$(document).on('touchmove scroll mousewheel DOMMouseScroll', throttle(250, function () {
	if (grStrategy1 && !$('#strategy-chart-1').hasClass('is-loaded')) {
		if (verge.inViewport($('#strategy-chart-1'), '15%')) {
			initChartStrategy1();
		}
	}

	if (grStrategy2 && !$('#strategy-chart-2').hasClass('is-loaded')) {
		if (verge.inViewport($('#strategy-chart-2'), '15%')) {
			initChartStrategy2();
		}
	}
}));
