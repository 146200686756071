const $toolsBtn = $('.js-tools-toogle'),
	$tools = $('.js-tools-panel');
const AddFavoritesText = localize({
	ru: 'Нажмите CTRL-D, для добавления страницы в избранное.',
	en: 'Press CTRL-D, to add the page to your bookmarks.'
});

import {showLayoutOverlay, hideLayoutOverlay, closeContainer} from '../layout/layout.js';

global.bookmark = function (a) {
	var url = window.document.location;
	var title = window.document.title;
	try {
		// Internet Explorer
		window.external.AddFavorite(url, title);
	}	catch (e) {
		try {
		// Mozilla
			window.sidebar.addPanel(title, url, '');
		} catch (e) {
			// Opera и Firefox 23+
			if (typeof (opera) == 'object' || window.sidebar) {
				a.href = url;
				a.rel = 'sidebar';
				a.title = title;
				a.url = url;
				return true;
			} else {
				// Unknown && Chrome
				alert(AddFavoritesText);
			}
		}
	}
	return false;
};

function openTools() {
	$toolsBtn.addClass('aside__tools--active');
	$('.js-tools-panel').addClass('tools-panel--opened');
	showLayoutOverlay();
}

function closeTools() {
	$toolsBtn.removeClass('aside__tools--active');
	$('.js-tools-panel').removeClass('tools-panel--opened');
	hideLayoutOverlay();
}

$toolsBtn.on('click', function (e) {
	e.preventDefault();
	if ($('.js-tools-panel').hasClass('tools-panel--opened')) {
		closeTools();
	} else {
		if ($('.aside--opened').length) {
			closeNav();
		}
		if ($('.aside__container--open').length) {
			closeContainer();
		}
		openTools();
	}
});

$(document).on('click', '.tools__link--share', function (e) {
	e.preventDefault();
	$('.js-tools-panel__share').toggleClass('tools-panel__share--opened');
});

$(document).on('click', '.tools__close', function (e) {
	e.preventDefault();
	closeTools();
});

$(document).on('keyup', function (e) {
	if (e.keyCode === 27 && $('.js-tools-panel').hasClass('tools-panel--opened')) {
		closeTools();
	}
});

$(document).on('click', function (e) {
	if ($('.js-tools-panel').hasClass('tools-panel--opened')) {
		if (!$('.js-tools-panel').is(e.target) && $('.js-tools-panel').has(e.target).length === 0 && !$toolsBtn.is(e.target) && $toolsBtn.has(e.target).length === 0) {
			closeTools();
		}
	}
});
