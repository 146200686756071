<template>
    <div class="media filter-container" :class="template">
        <a
            v-if="backLink"
            :href="backLink"
            class="media__back-link"
        >
            <span>{{ text.backLink }}</span>
        </a>
        <div
            class="filter-controls"
            :class="{'offset-top' : offsetTop}"
            ref="mediaFilterControls"
        >
            <slot
                v-if="filters.themes.length || filters.types.length"
                name="filter"
                :type="type"
                :filters="filters"
            ></slot>

            <media-years
                v-if="filters.years.length"
                :years="filters.years"
                :default-year="defaultYear"
            ></media-years>

        </div>
        <div class="media__content">
            <div v-if="notFound && !loading" class="not-found">
                <span class="not-found__title">{{ text.notFoundTitle }}</span>
                <p class="not-found__text">{{ text.notFoundText }}</p>
            </div>

            <slot
                v-else-if="!error && items.length"
                name="content"
                :items="items"
            ></slot>

        </div>
        <div v-if="loading" class="preloader">
            <img src="/images/svg/loader.svg">
        </div>
    </div>
</template>

<script>
const ScrollMagic = require('scrollmagic');
const queryString = require('query-string');
import { throttle } from 'throttle-debounce';
import { fetch as fetchPolyfill } from 'whatwg-fetch';
import MediaYears from './media-years.vue';

export default {
    name: 'media',
    props: {
        lang: String,
        type: String,
        path: String,
        handler: String,
        backLink: {
            type: String,
            default: ''
        },
        template: String,
        defaultId: String,
        defaultYear: String,
        defaultParams: String
    },
    components: {
        MediaYears
    },
    data() {
        return {
            items: [],
            error: false,
            loading: false,
            notFound: false,
            pathname: this.path,
            isFilterSelected: false,
            offsetTop: false,

            filters: {
                types: [],
                years: [],
                themes: []
            },

            page: {
                count: 0,
                total: 1,
                current: 1,
            },

            params: {
                page: 1,
                id: this.defaultId,
                key: this.template,
                year: this.defaultYear,
                [this.type]: this.defaultParams,
            },

            scroll: {
                scene: '',
                controller: ''
            },

            text: {
                backLink: this.lang == 'ru' ? 'Вернуться к альбомам' : 'Back to albums',
                notFoundTitle: this.lang == 'ru' ? 'Ничего не найдено' : 'No results found',
                notFoundText: this.lang == 'ru' ? 'По&nbsp;Вашему запросу ничего не&nbsp;нашлось.' : 'Sorry, no matches were found for your request. Try changing your search terms.'
            }
        }
    },
    mounted() {
        this.load();
        this.scrollMagic();
        this.listenScroll();
        this.$on('changeFilters', this.load);
        this.$on('changeID', id => this.changeID(id));
    },
    updated() {
        this.checkAndLoad();
    },
    beforeDestroy() {
        this.$off('changeFilters', this.load);
        this.$off('changeID', id => this.changeID(id));
    },
    methods: {
        clearEmptyParams() {
            let params = Object.assign({}, this.params);

            Object.keys(params).forEach((key, index) => {
                if (Array.isArray(params[key])) {
                    if (!params[key].length) delete params[key];
                } else if (!params[key]) {
                    delete params[key];
                };
            });

            return params;
        },
        load() {
            this.error = false;
            this.loading = true;

            if (this.params.page == 1) this.items = [];

            let searchParams = queryString.stringify(this.clearEmptyParams(), {
                arrayFormat: 'comma'
            });

            this.setLocationParams();

            fetch(`${this.handler}?${searchParams}`)
                .then(response => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        let error = new Error(response.statusText);
                        error.response = response;
                        throw error;
                    };
                })
                .then(response => response.json())
                .then(data => {
                    this.dataProcessing(data);
                }).catch(error => {
                    console.log(error);
                    this.error = error;
                    this.loading = false;
                });
        },
        dataProcessing(data) {
            this.updateFilters(data.filters);
            this.updateItems(data);
            this.loading = false;
        },
        updateItems(data) {
            if (data.nav) {
                if (data.nav.current == 1) this.items = [];
                this.page = data.nav;
            };

            if (Object.keys(data.items).length) {
                let items = data.items[this.params.year];

                this.notFound = false;

                if (this.items.length > 0) {
                    this.items = this.items.concat(items);
                } else {
                    this.items = items;
                };
            } else {
                this.items = [];
                this.notFound = true;
            };
        },
        updateFilters(filters) {
            if (filters.years) this.filters.years = filters.years;
            if (filters.types) this.filters.types = filters.types;
            if (filters.themes) this.filters.themes = filters.themes;
        },
        setLocationParams() {
            let string = this.pathname,
                params = this.clearEmptyParams(),
                query = '';

            string += `?year=${params.year}`;

            delete params.key;
            delete params.page;
            delete params.year;
            delete params.lang;
            delete params.lastUsedFilter;

            if (Object.keys(params).length > 0) {
                query = queryString.stringify(params, {
                    arrayFormat: 'comma'
                });
                string += '&';
            };

            string += `${query}`;

            if (history.pushState) {
                try {
                    history.pushState({}, '', string);
                    return string;
                } catch (err) {
                    console.error('Error in setLocationParams!');
                };
            } else {
                document.location.href = string;
            };

            return string;
        },
        listenScroll() {
            window.addEventListener('scroll', throttle(500, () => this.checkAndLoad()));
        },
        checkAndLoad() {
            let offsetTop = this.$el.offsetTop,
                offsetHeight = this.$el.offsetHeight,
                bottom = this.$el.offsetTop + offsetHeight,
                scroll = window.pageYOffset || document.documentElement.scrollTop,
                scrolled = scroll + document.documentElement.clientHeight;

            let condition = scrolled >= (bottom - 200) && !this.loading && this.page.current < this.page.total;

            if (condition) {
                this.params.page++;
                this.load();
            };
        },
        changeID(id) {
            this.params.id = id;
            this.setLocationParams();
        },
        scrollMagic() {
            this.scroll.controller = new ScrollMagic.Controller();
            this.scroll.scene = new ScrollMagic.Scene({
                triggerElement: this.$el,
                duration: 0,
                triggerHook: 0,
                reverse: true
            }).setPin(this.$refs.mediaFilterControls, {
                pushFollowers: false
            });
        
            this.scroll.controller.addScene([
                this.scroll.scene
            ]);
        }
    }
};
</script>